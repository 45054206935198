const { ethers } = require("ethers");
const { BigNumber } = require("ethers");

const encodeCallData = (from, to, token, tokenId) => {
  const ABI = [
    "function matchERC721UsingCriteria(address from,address to,address token,uint256 tokenId,bytes32 root,bytes32[] calldata proof) external returns (bool)",
  ];
  const iface = new ethers.utils.Interface(ABI);
  const data = iface.encodeFunctionData("matchERC721UsingCriteria", [
    from,
    to,
    token,
    tokenId,
    "0x0000000000000000000000000000000000000000000000000000000000000000",
    [],
  ]);
  return data;
};

const encodeOrderDetail = (
  addrs,
  uints,
  feeMethodsSidesKindsHowToCalls,
  calldataBuy,
  calldataSell,
  vs,
  rssMeatadata
) => {
  const ABI = [
    "function atomicMatch_(address[14] addrs,uint256[18] uints,uint8[8] feeMethodsSidesKindsHowToCalls,bytes calldataBuy,bytes calldataSell,bytes replacementPatternBuy,bytes replacementPatternSell,bytes staticExtradataBuy,bytes staticExtradataSell,uint8[2] vs,bytes32[5] rssMetadata) public payable",
  ];
  const iface = new ethers.utils.Interface(ABI);
  const data = iface.encodeFunctionData("atomicMatch_", [
    addrs,
    uints,
    feeMethodsSidesKindsHowToCalls,
    calldataBuy,
    calldataSell,
    "0x00000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
    "0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
    "0x",
    "0x",
    vs,
    rssMeatadata,
  ]);
  return data;
};

const encodeOrder = (
  marketId,
  exchange,
  taker,
  maker,
  target,
  recipient,
  makerRelayerFee,
  basePrice,
  buyExpirationTime,
  sellListingTime,
  sellExpirationTime,
  buySalt,
  sellSalt,
  v,
  r,
  s,
  buyer,
  seller,
  tokenAddress,
  tokenId,
  targetToken
) => {
  const addrs = [
    exchange,
    taker,
    maker,
    "0x0000000000000000000000000000000000000000",
    target,
    "0x0000000000000000000000000000000000000000",
    "0x0000000000000000000000000000000000000000",
    exchange,
    maker,
    "0x0000000000000000000000000000000000000000",
    recipient,
    target,
    "0x0000000000000000000000000000000000000000",
    "0x0000000000000000000000000000000000000000",
  ];
  const uints = [
    BigNumber.from(makerRelayerFee),
    BigNumber.from("0"),
    BigNumber.from("0"),
    BigNumber.from("0"),
    BigNumber.from(basePrice),
    BigNumber.from("0"),
    BigNumber.from(buyExpirationTime),
    BigNumber.from("0"),
    BigNumber.from(buySalt),
    BigNumber.from(makerRelayerFee),
    BigNumber.from("0"),
    BigNumber.from("0"),
    BigNumber.from("0"),
    BigNumber.from(basePrice),
    BigNumber.from("0"),
    BigNumber.from(sellListingTime),
    BigNumber.from(sellExpirationTime),
    BigNumber.from(sellSalt),
  ];
  const feeMethodsSidesKindsHowToCalls = [1, 0, 0, 1, 1, 1, 0, 1];
  const vs = [v, v];
  const rssMeatadata = [
    r,
    s,
    r,
    s,
    "0x0000000000000000000000000000000000000000000000000000000000000000",
  ];

  const buyCallData = encodeCallData(
    ethers.constants.AddressZero,
    buyer,
    tokenAddress,
    BigNumber.from(tokenId)
  );

  const sellCalldata = encodeCallData(
    seller,
    ethers.constants.AddressZero,
    tokenAddress,
    BigNumber.from(tokenId)
  );

  const order = {
    marketId,
    targetToken,
    value: BigNumber.from(basePrice),
    orderData: encodeOrderDetail(
      addrs,
      uints,
      feeMethodsSidesKindsHowToCalls,
      buyCallData,
      sellCalldata,
      vs,
      rssMeatadata
    ),
  };
  return order;
};

const encodePayload = (toAddr, orderDetais) => {
  const payload = ethers.utils.defaultAbiCoder.encode(
    [
      "address",
      "tuple(uint256 marketId, address targetToken, uint256 value, bytes orderData)[]",
    ],
    [toAddr, orderDetais]
  );
  return payload;
};

const randomSalt = (digit = 70) => {
  let num = "";
  for (let i = 0; i < digit; i+=1) {
    if (i === 0) {
      num += Math.floor(Math.random() * 9 + 1);
    } else {
      num += Math.floor(Math.random() * 10);
    }
  }
  return num;
};

module.exports.randomSalt = randomSalt;
module.exports.encodePayload = encodePayload;
module.exports.encodeOrder = encodeOrder;
module.exports.encodeCallData = encodeCallData;
module.exports.encodeOrderDetail = encodeOrderDetail;
