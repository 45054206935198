// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeChainId } from '../actions/wallet';
import SearchMoudleComponents from './searchModule';
import CartDialogComponents from './cartDialog';
import './comheader.scss';
import oswaplogo from '../assets/img/oswaplogo.png';
import projectShopping from '../assets/img/projectShopping.png';
import userlogo from '../assets/img/userlogo.png';
import { connect } from '../assets/js/common';
import { changeShowCartDialog } from '../actions/cart';

function ShowName() {
  const [showName, setShowName] = useState('NFT Aggregator');
  const changeName = () => {
    let cName = 'NFT Aggregator';
    setInterval(() => {
      if (cName === 'NFT Aggregator') {
        cName = 'Built on LayerZero';
        setShowName('Built on LayerZero');
      } else {
        cName = 'NFT Aggregator';
        setShowName('NFT Aggregator');
      }
    }, 3000);
  };
  useEffect(() => {
    changeName();
  }, []);
  return <div className="comName titleName">{showName}</div>;
}
function Headercom() {
  const showCart = useSelector((state) => state.cart.showCartDialog);
  const isConnected = useSelector((state) => state.auth.isConnected);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const handleConnect = () => {
    connect(dispatch);
  };
  const changeDialog = () => {
    dispatch(changeShowCartDialog({ showCartDialog: !showCart }));
  };
  window.ethereum.on('accountsChanged', () => {
    sessionStorage.clear();
    // navigate('/');
    window.location.reload();
    // const provider = getProvider()
    // const signer = provider.getSigner();
    // const account = accounts[0];
    // onConnect(account, provider, signer,appDispatch);
  });
  window.ethereum.on('chainChanged', (chainId) => {
    connect(dispatch);
    dispatch(changeChainId({ chainId }));
  });
  return (
    <div className="headers">
      <div className="headers-leftcontent">
        <a href="https://www.oswap.exchange">
          <ShowName />
          <img className="logo" src={oswaplogo} alt="" />
          <span className="versionB">beta</span>
        </a>
      </div>
      <SearchMoudleComponents />
      {/* <div onClick={()=>{
          logoutAuth()
        }}>tuichu</div> */}
      {!isConnected ? (
        <div
          className="connect-button"
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => {
            // @ts-ignore
            handleConnect();
          }}
        >
          Connect
        </div>
      ) : (
        <div className="loginInfo">
          <div className="cartGroup">
            <div
              role="button"
              tabIndex="-1"
              onKeyDown={() => {}}
              onClick={() => changeDialog()}
            >
              <img className="spImg" src={projectShopping} alt="iconlogo" />
            </div>

            {showCart ? (
              <CartDialogComponents changeShowCart={() => changeDialog()} />
            ) : (
              ''
            )}
          </div>
          <Link to="/user/main">
            <img className="usLogo" src={userlogo} alt="" />
          </Link>
        </div>
      )}
    </div>
  );
}

export default Headercom;
