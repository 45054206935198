import React, { useEffect, useState } from 'react';
import { utils } from 'ethers';
import moment from 'moment';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import {getAssetEventList} from '../../../services/collectionDetail.services'
import iconlogo from '../../../assets/img/iconlogo.png'
import coinIcon from '../../../assets/img/coinIcon.png'
import shares from '../../../assets/img/shares.png'
import './activity.scss'
import {formatAddress} from '../../../utils/tools'
import NoDataComponents from '../../../components/noData';

function ActivityTables(props){
  const {slug,tokenId,contract} = props
  const [cursor,setCursor] = useState('');
  const [eventList,setEventList] = useState([])
  const [lastPage,setLastPage] = useState(false)
  const [canclick,setCanclick] = useState(true)
  function aeventList(){
    const params = {
      slug,
      tokenId,
      contract,
    }
    if(cursor) params.cursor = cursor
    getAssetEventList(params,(response)=>{
      if(response.next) {
        setCursor(response.next);
      }else{
        setCursor('');
        setLastPage(true)
      }
      setCanclick(true)
      setEventList(response.assetEvents)
    })
  }
  useEffect(()=>{
    aeventList()
  },[])
  function rederEventList(item,index){
    return <tr key = {index}>
      <td>
        <img className='eventLogo' src={iconlogo} alt="iconlogo" />
        <span>Sale</span>
      </td>
      <td>
        <img className='priceIcon' src={coinIcon} alt="icon" />
        <span>{utils.formatEther(item.totalPrice)}</span>
      </td>
      <td>
        <Link target="_blank" href={`https://etherscan.io/address/${item.transaction.from_account.address}`} className = "linkInfo">
          <span>{formatAddress(item.transaction.from_account.address)}</span>
          <img className='shareImg' src={shares} alt="" />
        </Link>
      </td>
      <td>
      <Link target="_blank" href={`https://etherscan.io/address/${item.transaction.to_account.address}`} className = "linkInfo">
        <span>{formatAddress(item.transaction.to_account.address)}</span>
        <img className='shareImg' src={shares} alt="" />
      </Link>
      </td>
      <td>{moment(item.eventTimestamp).startOf('day').fromNow()}</td>
    </tr>
  }
  return (
    <div className='activityInfo'>
      <div className='activeHeader'>
        <h3 className='actitle'>Activity</h3>
        {/* <select name="" id=""></select> */}
      </div>
      {
        eventList && eventList.length > 0?
        <table className='activity-table'>
          <thead>
            <tr>
              <th>Event</th>
              <th>Price</th>
              <th>From</th>
              <th>To</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {
              eventList.map((item,index)=>rederEventList(item,index))
            }
          </tbody>
        </table>
        :
        <NoDataComponents />
      }
      
      {
        !lastPage && eventList && eventList.length > 0?
        <div className='addmores' 
          role="button"
          tabIndex = {-1}
          onKeyDown = {()=>{}}
          onClick={()=>{
            setCanclick(false)
            aeventList()
          } 
        }
        >{canclick?'Load More':'Loading...'}</div>
        :''
      }
    </div>
  )
}
ActivityTables.propTypes = {
  slug:PropTypes.string.isRequired,
  tokenId:PropTypes.string.isRequired,
  contract:PropTypes.string.isRequired,
}
export default ActivityTables
