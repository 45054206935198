import React from 'react';
import './comfooter.scss'
import Link from '@mui/material/Link';
import oswaplogo from '../assets/img/oswaplogo.png'
// import footerone from '../assets/img/footerone.png'
// import footertwo from '../assets/img/footertwo.png'

function ComfooterComponents(){
  return (
    <section className="comfooter">
      <div className='footer-info'>
        <img className='footer-logo' src={oswaplogo} alt="logo" />
        <div className='footer-right'>
          <Link target="_blank" className = "hreflink" href="https://www.dlab.zone/">
            dLab
          </Link>
          <Link target="_blank" className = "hreflink" href="https://discord.gg/jh8QU5529X">
            Discord
          </Link>
          <Link target="_blank" className = "hreflink" href="https://twitter.com/OmnichainSwap">
            Twitter
          </Link>
          <Link target="_blank" className = "hreflink" href="https://github.com/The-dLab/">
            Github
          </Link>
        </div>
      </div>
      <div className='bgimg'> </div>
    </section>
  )
}
export default ComfooterComponents
