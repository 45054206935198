import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import getAllCollectionList from '../../services/collectionList.services'
import CollectionComponents from '../../components/collectionsTable'
import './main.scss'
import DivisionLine from '../../components/divisionLine'
import LoadingComponents from "../../components/loading"

let collectListPage = 1;
function CollectionListPage(){
  const [total,setTotal] = useState(0);
  const [cList,setClist] = useState([])
  const showList = true
  function getList(){
    getAllCollectionList({
      page:collectListPage,
      size:15,
    },(response)=>{
      setTotal(response.total);
      setClist(response.items);
    });
  }
  useEffect(()=>{
    getList();
  },[]);
  return (
    <div className='collectionList '>
      <DivisionLine />
      {
        cList && cList.length > 0?
        <CollectionComponents name = "All collections" noshowIndex = {showList}  list = {cList}/>
        :<LoadingComponents />
      }
      <div className='listpagina'>
      <Stack spacing={2} sx={{
            '& .MuiPagination-root':{
              'margin': '0 auto',
            },
          }}>
            <Pagination sx={{
              '&.MuiPagination-root .MuiPaginationItem-icon':{
                'width':20,
                'height':20,
              },
              '&.MuiPagination-root .MuiPaginationItem-root':{
                'fontSize':16,
              },
            }} count={Math.ceil(total/15)} variant="outlined" page ={collectListPage} shape="rounded" onChange = {(event,pageNum)=>{
              collectListPage = pageNum
              getList();
            }} />
          </Stack>
      </div>
    </div>
  )
}
export default CollectionListPage
