import { createSlice } from '@reduxjs/toolkit'

const initialWalletState = {
  provider: null, 
  signer: null,
  chainId:'',
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: initialWalletState,
  reducers: {
    setProvider:(state,action)=>{
      state.provider = action.payload.provider
      state.signer = action.payload.signer
    },
    removeProvider:(state)=>{
      state.provider = null;
      state.signer = null
    },
    changeChainId:(state,action)=>{
      state.chainId = action.payload.chainId
    },
    
  },
})

export const { setProvider,removeProvider,changeChainId } = walletSlice.actions

// export default walletSlice
