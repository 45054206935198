import React from 'react';
import indexline from '../assets/img/indexline.png'
import './divisionLine.scss'

function DivisionLine (){
  return (
    <img className='indexline' src={indexline} alt="indexline" />
  )
}
export default DivisionLine
