import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from "react-select";
import PropTypes,{ shape} from 'prop-types';
import './projectSlider.scss'
import lefthide from '../../../assets/img/lefthide.png'
import openinfo from '../../../assets/img/openinfo.png'
import searchIcon from '../../../assets/img/searchIcon.png'

let lowPrice = ''
let highPrice = '';
function ProjectSliderComponents(props){
  const {traits,paymentTokens,childrenChangeSearchprice,childernChangeSelect,childrenChangeBuyBow} = props;
  const [isSliderAll,setisSliderAll] = useState(true)
  const [selectSymbol, setSelectSymbol] = useState(paymentTokens[0].symbol);
  const [traitsList,setTraitsList] = useState(traits);
  const showKey = {}
  for(let i=0;i< Object.keys(traits).length;i+=1){
    showKey[Object.keys(traits)[i]] = 'block';
  }
  const [isShowMoudle,setIsShowMoudle] = useState(showKey);
  const changeShowInfo =(key)=>{
    let instring = ""
    if(isShowMoudle[key] === 'block'){
      instring = 'none'
    }else{
      instring = 'block'
    }
    setIsShowMoudle((data)=>({...data,[key]:instring}));
  }
  const selectMoudleList =(e,key)=>{
    const newInfo = {}
    if(e.target.value){
      for(let i = 0;i<Object.keys(traits[key]).length;i+=1){
        if(Object.keys(traits[key])[i].indexOf(e.target.value)>-1){
          newInfo[Object.keys(traits[key])[i]] = traits[key][Object.keys(traits[key])[i]]
        }
      }
      setTraitsList((data)=>({...data,[key]:newInfo}))
    }else{
      setTraitsList((data)=>({...data,[key]:traits[key]}))
    }
  }
  const handleChange = (selectedOption) => {
    setSelectSymbol(selectedOption.value)
  };
  const handleSwitchChange = (e)=>{
    childrenChangeBuyBow(e.target.checked)
  }
  const checktraits = (e,key,trankey)=>{
    childernChangeSelect(e,key,trankey);
  }
  const selectPrice = ()=>{
    if(lowPrice && highPrice){
      const price = {
        symbol:selectSymbol,
        "low": Number(lowPrice),
        "high": Number(highPrice),
      }
      childrenChangeSearchprice(price);
    }
    
  }
  function renderTrankey(key,trankey){
    return <div className='selectList' key = {trankey}>
      <div className='checkoutbox'>
        <FormControlLabel 
          sx = {{
            '& .MuiTypography-root':{
              fontSize: '12px',
              fontFamily: 'Roboto-Bold',
              fontWeight: 300,
              color: '#000000',
              lineHeight: '14px',
            },
          }}
          onChange={(e)=>{
            checktraits(e,key,trankey)
          }}
          control={<Checkbox 
            sx={{ 
              '& .MuiSvgIcon-root': { fontSize: 16,color:'#000' } }}
          />} label={trankey} />
      </div>
      <div className='number'>{traitsList[key][trankey]}</div>
    </div>
  }
  function renderTraits(key){
    return <div className='selectListcom' key = {key}>
      <div className='groupTitle'
        role= "button"
        tabIndex = {0}
        onKeyDown = {()=>{}} 
        onClick={()=>{changeShowInfo(key)}}
      >
        <span>{key}</span>
        <img style={{transform:isShowMoudle[key] === 'none'?'rotate(180deg)':'rotate(0deg)'}} className='selectopen' src={openinfo} alt="" />
      </div>

      <div className='moudleContent' style={{display:isShowMoudle[key]}}>
        <div className='searchGroup'>
          <img className='searchicon' src={searchIcon} alt="" />
          <input 
            className='selectSearch' 
            type="text" 
            onChange = {(e)=>{selectMoudleList(e,key)}}
          />
        </div>
        
        <div className='listGroup'>
          {
            Object.keys(traitsList[key]).map(trankey=>renderTrankey(key,trankey))
          }
        </div>
      </div> 
      
    </div>
  }
  return (
    <div  className={isSliderAll?'sliderContent bgSlider':'sliderContent smallSlider'} >
      {
        !isSliderAll?
        <div 
          className='filtersIcon smallIcon'
          role= "button"
          tabIndex = {-1}
          onKeyDown={()=>{}}
          onClick={()=>{setisSliderAll(true)}}
        >
          <img src={lefthide} alt="" />
        </div>
        :
        <div>
          <div className='projectSliderList filterlist'>
            <h3 className='filtersName'>Filters</h3>
            <div 
              className='filtersIcon'
              role= "button"
              tabIndex = {-2}
              onKeyDown = {()=>{}}
              onClick={()=>{setisSliderAll(false)}}
            >
              <img src={lefthide} alt="" />
            </div>
          </div>
          <div className='projectSliderList buyNowlist'>
            <h3 className='buyNowName'>Buy Now</h3>
            <Switch onChange={handleSwitchChange} color="secondary"/>
          </div>
          <div className='selectListcom'>
            <h3 className='groupTitle'>Price Range</h3>
            <div className='inputGroup'>
              <input className='priceInput' onChange={(e)=>{
                lowPrice =e.target.value;
              }} type="text" placeholder='Low' />
              <span className='line'>-</span>
              <input className='priceInput' 
                type="text" 
                placeholder='High'
                onChange={(e)=>{
                  highPrice =e.target.value;
                }}
              />
              <Select className='selectList' 
                placeholder = {selectSymbol} 
                value={selectSymbol} 
                onChange={handleChange} 
                options={paymentTokens}
              />
            </div>
            <div 
              className='applyButton' 
              role= "button"
              tabIndex = {-3}
              onKeyDown= {()=>{}}
              onClick={()=>{selectPrice()}}
            >
              Apply
            </div>
          </div>
          {
            
            Object.keys(traitsList).map((key)=>renderTraits(key))
          }
          
          {/* <div className='selectListcom'>
            <h3>
              <span>Plot</span>
              <img className='selectopen' src={openinfo} alt="" />
            </h3>
            <div className='plotGroup'>
              <div className='plotInputGroup'>
                <input className='plotinput' type="text" placeholder='0' />
                <p>Min</p>
              </div>
              <div className='plotline'>-</div>
              <div className='plotInputGroup'>
                <input className='plotinput' type="text" placeholder='99999'/>
                <p>Max</p>
              </div>
            </div>
          </div> */}
        </div>
      }
    </div>
  )
}
ProjectSliderComponents.propTypes = {
  traits:PropTypes.oneOfType([PropTypes.object]),
  paymentTokens:PropTypes.arrayOf(shape({
    symbol:PropTypes.string,
    decimals:PropTypes.number,
  })),
  childrenChangeSearchprice:PropTypes.func,
  childernChangeSelect:PropTypes.func,
  childrenChangeBuyBow:PropTypes.func,
}
export default ProjectSliderComponents
