import {requestPost,requestGet} from '../utils/request'

function addCart(params,callback){
  requestPost('/v1/cart/add',params,(response)=>{
    callback(response)
  })
}

function getShoppingList(callback){
  requestGet('/v1/cart/get',{},(response)=>{
    callback(response)
  })
}
function removeShoppingList(params,callback){
  requestPost('/v1/cart/remove',params,(response)=>{
    callback(response)
  })
}
function clearShoppingList(callback){
  requestPost('/v1/cart/clear',{},(response)=>{
    callback(response)
  })
}

export {
  addCart,
  getShoppingList,
  removeShoppingList,
  clearShoppingList,
} 
