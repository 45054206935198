import {requestGet,requestPost} from '../utils/request'

function getCollectionInfo(params,callback){
  requestGet('/v1/collection/get',params,(response)=>{
    callback(response)
  })
}

function getCollectionAssetList(params,callback){
  requestPost('/v1/asset/list',params,(response)=>{
    callback(response)
  })
}
  function getCollectionEventList(params,callback){
  requestGet('/v1/collection/event/list',params,(response)=>{
    callback(response)
  })
}
export {
  getCollectionInfo,
  getCollectionAssetList,
  getCollectionEventList,
} 
