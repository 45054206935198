import { ethers } from 'ethers';

function numberFormat(val, type,fixedNum) {
  let formatNumber = '';
  if (type === 'K' || type === 'k') {
    if (val - 1000 > 0) {
      formatNumber = parseFloat(val / 1000).toFixed(2) + type;
    } else {
      formatNumber = parseFloat(val).toFixed(2);
    }
    return formatNumber;
  }
  if(fixedNum){
    formatNumber = parseFloat(val).toFixed(fixedNum);
    return formatNumber.toLocaleString();
  }
  return val.toLocaleString();
  
}
function formatAddress(address, length = 'short') {
  let newAddress = '';
  if (address && length === 'short') {
    newAddress = `${address.substring(0, 6)}...${address.substring(
      address.length - 4,
      address.length
    )}`;
  } else if (address && length === 'long') {
    newAddress = `${address.substring(0, 12)}...${address.substring(
      address.length - 8,
      address.length
    )}`;
  }
  return newAddress;
}
function isLocalChain(chainId) {
  if (chainId && (chainId === '0x4' || chainId === '0x1')) {
    return true;
  }
  return false;
}
const debounce = (func, delay) => {
  let timer;
  return (e) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(e);
    }, delay);
  };
};
function ethToUsdcChainName() {
  if (process.env.REACT_APP_STAGE === 'dev') {
    return 'RINKEBY';
  }
  return 'MAINNET';
}
function getProvider() {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
  provider.on('network', (newNetwork, oldNetwork) => {
    // When a Provider makes its initial connection, it emits a "network"
    // event with a null oldNetwork along with the newNetwork. So, if the
    // oldNetwork exists, it represents a changing network
    if (oldNetwork) {
      window.location.reload();
    }
  });
  return provider;
}
export {
  numberFormat,
  formatAddress,
  debounce,
  isLocalChain,
  ethToUsdcChainName,
  getProvider,
};
