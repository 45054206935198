import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getTopCollection } from '../../services/index.services';
import './main.scss';
import IntroductionComponents from './components/introduction';
import DiscoveryComponents from './components/discovery';
import DivisionLine from '../../components/divisionLine';
import CollectionComponents from '../../components/collectionsTable';
import ComfooterComponents from '../../components/comfooter';
import { indexUrl } from '../../utils/index-url';

export default function IndexPage() {
  useEffect(() => {
    window.open(indexUrl);
  }, []);

  const [topCollections, setTopCollections] = useState([]);
  function updateinfo(data, respData) {
    return data.concat(respData);
  }
  useEffect(() => {
    // getCoinPrice();
    // getCoinList();
    getTopCollection({}, (respData) => {
      setTopCollections((data) => updateinfo(data, respData));
    });
  }, []);
  return (
    <div className="indexPageMain">
      <IntroductionComponents />
      <DiscoveryComponents />
      <DivisionLine />
      <CollectionComponents name="Top collections" list={topCollections} />
      {topCollections.length > 0 ? (
        <Link to="/collectionList/main">
          <div className="allCollection">All Collections</div>
        </Link>
      ) : (
        ''
      )}
      <ComfooterComponents />
    </div>
  );
}
