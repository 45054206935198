import React, { useEffect, useState } from 'react';
import { utils } from 'ethers';
// import PropTypes from 'prop-types';
import {useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeShoppingList } from '../actions/cart';
import createNotification from '../utils/toast';
import {numberFormat} from "../utils/tools"
import {removeShoppingList,clearShoppingList} from '../services/cart.services'
import closedialog from '../assets/img/closedialog.png'
// import triangle from '../assets/img/triangle.png'
import bigrz from '../assets/img/bigrz.png'
import coiniconbg from '../assets/img/coiniconbg.png'
import delate from '../assets/img/delate.png'
import NoDataComponents from "./noData"
import {getAllCartFee} from '../assets/js/common'
import './shoppingList.scss'

function ShoppingListComponents(){
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showButton,setShowButton] = useState(true)
  const [showAllFee,setShowAllFee] = useState(0)
  const ethPrice = useSelector((state) => state.ethPrice.price)
  useEffect(()=>{
    const pageHref = window.location.href;
    if(pageHref.indexOf('pay/main') > -1){
      setShowButton(false)
    }
  },[])
  let shoppinngList = Object.assign([],useSelector((state) => state.cart.shoppinngList));
  const delateShopping = (id,index)=>{
    removeShoppingList({id},(response)=>{
      if(response){
        createNotification('remove success!')
        shoppinngList.splice(index,1)
        dispatch(changeShoppingList({shoppinngList}));
      }
    })
  }
  const clearShopping = ()=>{
    clearShoppingList((response)=>{
      if(response){
        createNotification('clear success!')
        shoppinngList = []
        dispatch(changeShoppingList({shoppinngList}));
      }
    })
  }
  useEffect(()=>{
    // getAllCartFee(shoppinngList)
    setShowAllFee(getAllCartFee(ethPrice,shoppinngList))
  },[shoppinngList]);
  return (
    <div className='buyContent'>
      <ul className='headerList'>
        <li className='itemsList'>Items</li>
        <li className='priceList'>Price</li>
        <li className='quantityList'>Quantity</li>
        <li className='clearList'>Clear</li>
      </ul>
      {
        shoppinngList && shoppinngList.length > 0 ?
        <div>
          <ul className='headerList allInfo'>
            <li className='itemsList'>
              <div className='groupes onenumber'>
                <p className='number'>{shoppinngList.length}</p>
                <p className='name'>Total</p>
              </div>
            </li>
            <li className='priceList'>
              <div className='groupes'>
                <p className='number'>${numberFormat(showAllFee)}</p>
                <p className='name'>Total</p>
              </div>
            </li>
            <li className='quantityList'>
              <div className='groupes'>
                <p className='number'>{shoppinngList.length}</p>
                <p className='name'>Total</p>
              </div>
              
            </li>
            <li className='clearList'>
              <div className='groupes'
              role="button"
              tabIndex = {-1}
              onKeyDown = {()=>{}}
              onClick = {()=>clearShopping()}
              >
                <img className='clearAll' src={closedialog} alt="" />
                <p className='name'>clear all</p>
              </div>
            </li>
          </ul>
          <ul className='listContent'>
            {
              shoppinngList.map((item,index)=> 
                <li key = {item.id}>
                  <ul className='content'>
                    <li className='itemsContent'>
                      <img className='infoLogo' src={item.imageUrl} alt="" />
                      <div className='nameGroup'>
                        <h4 className='title'>{item.name}</h4>
                        <div className='desc'>
                          <span>{item.collection.name}</span>
                          {
                            item.collection.safelistRequestStatus === 'verified'?
                            <img className='rzimg' src={bigrz} alt="" />
                            :''
                          }
                          
                        </div>
                      </div>
                    </li>
                    <li className='priceContent'>
                      <img className='iconbg' src={coiniconbg} alt="" />
                      <div className='pandNum'>
                        <h4 className='pNum'>{utils.formatEther(item.currentPrice)}</h4>
                        <p className='uNum'>(${numberFormat(ethPrice * utils.formatEther(item.currentPrice))})</p>
                      </div>
                    </li>
                    <li className='quantityContent'>1</li>
                    <li className='clearContent'>
                      <div
                        role="button"
                        tabIndex={-1}
                        onKeyDown={()=>{}}
                        onClick={()=>delateShopping(item.id,index)}
                      >
                        <img className='delate' src={delate} alt="" />
                      </div>
                      
                    </li>
                  </ul>
                </li>
              )
            }
          </ul>
        </div>
        :
        <div className='noList'>
          <NoDataComponents/>
        </div>
        
      }
      
      {
        showButton && shoppinngList && shoppinngList.length > 0?<div 
        className='payButton'
        role="button"
        tabIndex = {-1}
        onKeyDown = {()=>{}}
        onClick = {()=>{
          navigate(`/pay/main/`)
        }} 
        >Proceed to checkout</div>
        :''
      }
      </div>
  )
}
export default ShoppingListComponents
