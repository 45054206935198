import {requestGet} from '../utils/request'

function getAssetDetail(params,callback){
  requestGet('/v1/asset/get',params,(response)=>{
    callback(response)
  })
}
function getAssetEventList(params,callback){
  requestGet('/v1/asset/event/list',params,(response)=>{
    callback(response)
  })
}

export {
  getAssetDetail,
  getAssetEventList,
} 
