import React from 'react';
import './synopsis.scss';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function SynopsisComponents(props) {
  const { itemInfo } = props;
  const navigate = useNavigate();
  function todetail() {
    navigate(
      `/collectionDetail/main/${itemInfo.assetContract.address}/${itemInfo.tokenId}/${itemInfo.collection.slug}`
    );
  }
  return (
    <div
      className="ProjetSynopsis"
      role="button"
      tabIndex={-1}
      onKeyDown={() => {}}
      onClick={() => {
        todetail();
      }}
    >
      <img className="projectimg" src={itemInfo.imageUrl} alt="" />
      <h4 className="projectTitle">{itemInfo.name}</h4>
      <div className="priceInfo">
        <p className="priceNum">#{itemInfo.tokenId}</p>
      </div>
    </div>
  );
}
SynopsisComponents.propTypes = {
  itemInfo: PropTypes.shape({
    name: PropTypes.string,
    assetContract: PropTypes.shape({
      address: PropTypes.string,
    }),
    collection: PropTypes.shape({
      slug: PropTypes.string,
    }),
    tokenId: PropTypes.string,
    imageUrl: PropTypes.string,
  }).isRequired,
};
export default SynopsisComponents;
