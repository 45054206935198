import React from 'react';
import { utils } from 'ethers';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import './projetSynopsis.scss';
import { useNavigate } from 'react-router-dom';
import { changeShoppingList } from '../../../actions/cart';
import { addCart, removeShoppingList } from '../../../services/cart.services';
import createNotification from '../../../utils/toast';
import projectShopping from '../../../assets/img/projectShopping.png';
import coiniconbg from '../../../assets/img/coiniconbg.png';
import removeCart from '../../../assets/img/removeCart.png';

function ProjetSynopsisComponents(props) {
  const { itemInfo, slug } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shoppinngList = Object.assign(
    [],
    useSelector((state) => state.cart.shoppinngList)
  );
  const address = useSelector((state) => state.auth.address);
  const todetail = () => {
    navigate(
      `/collectionDetail/main/${itemInfo.assetContract.address}/${itemInfo.tokenId}/${slug}`
    );
  };
  let shoppingInCartIndex = 0;
  const isShowAddCart = () => {
    let isShow = true;
    if (shoppinngList && shoppinngList.length > 0 && itemInfo && itemInfo.id) {
      for (let i = 0; i < shoppinngList.length; i += 1) {
        if (shoppinngList[i].id === itemInfo.id) {
          isShow = false;
          shoppingInCartIndex = i;
        }
      }
    }
    return isShow;
  };
  let showAddCart = isShowAddCart();
  const addShoppingToCart = (e) => {
    e.stopPropagation();
    if (itemInfo.owner.address === address) {
      createNotification("'You can't buy your own NFTs.!", 'error');
      return;
    }
    if (showAddCart) {
      addCart({ id: itemInfo.id }, (response) => {
        showAddCart = false;
        if (response) createNotification('success!');
        shoppinngList.push(itemInfo);
        dispatch(changeShoppingList({ shoppinngList }));
      });
    } else {
      removeShoppingList({ id: itemInfo.id }, (response) => {
        showAddCart = true;
        if (response) createNotification('success!');
        shoppinngList.splice(shoppingInCartIndex, 1);
        dispatch(changeShoppingList({ shoppinngList }));
      });
    }
  };
  return (
    <div
      className="ProjetSynopsis"
      role="button"
      tabIndex={-1}
      onKeyDown={() => {}}
      onClick={() => {
        todetail();
      }}
    >
      <img className="projectimg" src={itemInfo.imageUrl} alt="" />
      <h4 className="projectTitle">{itemInfo.name}</h4>
      {itemInfo.currentPrice ? (
        <div className="priceInfo">
          <div className="pricecon">
            <img className="iconimg" src={coiniconbg} alt="" />
            <p className="priceNum">
              {utils.formatEther(itemInfo.currentPrice)}
            </p>
          </div>

          <div
            role="button"
            tabIndex={-2}
            onKeyDown={() => {}}
            onClick={(e) => {
              addShoppingToCart(e);
            }}
          >
            {showAddCart ? (
              <img className="shop" src={projectShopping} alt="" />
            ) : (
              <img className="shop" src={removeCart} alt="" />
            )}
          </div>
        </div>
      ) : (
        <div className="priceInfo"> </div>
      )}
    </div>
  );
}
ProjetSynopsisComponents.propTypes = {
  itemInfo: PropTypes.shape({
    name: PropTypes.string,
    assetContract: PropTypes.shape({
      address: PropTypes.string,
    }),
    tokenId: PropTypes.string,
    imageUrl: PropTypes.string,
    currentPrice: PropTypes.string,
    id: PropTypes.string,
    owner: PropTypes.shape({
      address: PropTypes.string,
    }),
  }),
  slug: PropTypes.string,
};
export default ProjetSynopsisComponents;
