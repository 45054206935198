const routerAddress = {
  // Rinkeby
  'chain0x4':'0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  // BSCTest
  'chain0x61':'0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  // FUJi
  'chain0xa869':'0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  // Polygon Testnet
  'chain0x13881':'0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  // Fantom testnet
  'chain0xfa2':'0xa6AD18C2aC47803E193F75c3677b14BF19B94883',
  // Ethereum
  'chain0x1':'0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F',
  // BSC
  'chain0x38':'0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  // Avalanche
  'chain0xa86a':'0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  // Polygon
  'chain0x89':'0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  // Arbitrum
  'chain0xa4b1':'0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  // Optimism
  // 'chain0xa':'',
  // Fantom
  'chain0xfa':'0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',

}
export default routerAddress
