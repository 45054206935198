import React from 'react';
import moment from 'moment';
import { utils } from 'ethers';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import './activityTable.scss'
import opensea from '../assets/img/opensea.png'
import coinIcon from '../assets/img/coinIcon.png'
import shares from '../assets/img/shares.png'
import {formatAddress} from '../utils/tools'

function ActivityTableComponents(props){
  const {activityList} =props
  function activeList(item,index){
    return <tr key = {index}>
      <td className='tableone'>
        <img className='eventLogo' src={opensea} alt="opensea" />
        <span>Sale</span>
      </td>
      <td className='tabletwo'>
        <img className='coinIcon' src={item.asset.imageUrl} alt="icon" />
        <span className='names'>{item.asset.collection.name} #{item.asset.tokenId}</span>
      </td>
      <td className='tableThree'>
        <img className='priceIcon' src={coinIcon} alt="icon" />
        <span>{utils.formatEther(item.totalPrice)}</span>
      </td>
      <td className='tableFour'>{item.quantity}</td>
      <td className='tableFive'>
        <Link target="_blank" href={`https://etherscan.io/address/${item.transaction.from_account.address}`} className = "linkInfo">
          <span>{formatAddress(item.transaction.from_account.address)}</span>
          <img className='shareImg' src={shares} alt="linkimg" />
        </Link>
      </td>
      <td className='tableSix'>
        <Link target="_blank" href={`https://etherscan.io/address/${item.transaction.to_account.address}`} className = "linkInfo">
          <span>{formatAddress(item.transaction.to_account.address)}</span>
          <img className='shareImg' src={shares} alt="linkimg" />
        </Link>
      </td>
      <td className='tableSeven'>{moment(item.eventTimestamp).startOf('day').fromNow()}</td>
    </tr>
  }
  return (
    <section className='activity'>
      <table className='activity-table'>
        <thead>
          <tr>
            <th className='tableone'>Event</th>
            <th className='tabletwo'>Item</th>
            <th className='tableThree'>Price</th>
            <th className='tableFour'>Quantity</th>
            <th className='tableFive'>From</th>
            <th className='tableSix'>To</th>
            <th className='tableSeven'>Date</th>
          </tr>
        </thead>
        <tbody>
          {
            activityList.map((item,index)=>activeList(item,index))
          }
        </tbody>
      </table>
    </section>
  )
}
ActivityTableComponents.propTypes = {
  activityList:PropTypes.oneOfType([PropTypes.array]),
}
export default ActivityTableComponents
