import {requestPost} from '../utils/request'

function getAuth(address,callback){
  requestPost('/auth/authorize',{
    address,
  },(response)=>{
    if (response.accessToken) {
      const resInfo = Object.assign(response,{address})
      sessionStorage.setItem('auth', JSON.stringify(resInfo));
    }
    callback(response.accessToken)
  })
}
export default getAuth
