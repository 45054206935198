const targetChainInfoTest = {
  chainId: 10001,
  address: '0x27748f85afdc5A3094AC282199cCF9BE61CC290a',
  poolId: 1,
  usdcAddress: '0x1717A0D5C8705EE89A8aD6E808268D6A826C97A4',
  decimals: 6,
  wethAddress: '0xc778417e063141139fce010982780140aa0cd5ab',
  swapRouterAddress:'0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
};
const targetChainInfoProduction = {
  chainId: 1,
  address: '0x64f07081Fc51Ec7859DF57cBD9B5d110c9b268d1',
  poolId: 1,
  usdcAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  decimals: 6,
  wethAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  swapRouterAddress:'0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F',
};
const targetInfo = () => {
  if (process.env.REACT_APP_STAGE === 'dev') {
    return targetChainInfoTest;
  }
  return targetChainInfoProduction;
};
export default targetInfo;
