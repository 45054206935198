import React, { useEffect, useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import { useDispatch,useSelector } from 'react-redux';
import { changeSelectColistList } from '../../../actions/cart';
import {numberFormat} from "../../../utils/tools"
import './myCoinList.scss'

// let inputInfo = 0;
function MyCoinListComponents(props) {
  const delIndex = useSelector((state) => state.cart.delIndex)
  const isClear = useSelector((state) => state.cart.isClear)
  const selectList = Object.assign([],useSelector((state) => state.cart.selectCoinList))
  const dispatch = useDispatch();
  const {listInfo,listIndex} = props
  let listIsChecked = {...listInfo}
  listIsChecked.checked = false
  const [myListInfo,setMyListInfo] = useState(listIsChecked)
  const [inputInfo,setInputInfo] = useState(0)
  const coinPrice = useSelector((state) => state.coinListPrice.priceAggregate)
  useEffect(()=>{
    listIsChecked = {...listInfo}
    listIsChecked.checked = false
    setMyListInfo(listIsChecked)
    setInputInfo(0)
  },[listInfo])
  useEffect(()=>{
    if(listIndex === delIndex){
      setMyListInfo(data =>({...data,'checked':false}))
    }
  },[delIndex])
  useEffect(()=>{
    setMyListInfo(data =>({...data,'checked':false}))
  },[isClear])
  
  const handleChange=(e)=>{
    e.stopPropagation();
    const ischecked = e.target.checked
    if(ischecked){
      const info = {
        selectIndex:listIndex,
        selectInfo:myListInfo,
        selectVal:inputInfo,
      }
      selectList.push(info);
      setMyListInfo(data =>({...data,'checked':true}));
      dispatch(changeSelectColistList({selectList}));
    }else{
      const newList = [];
      for(let i=0;i<selectList.length;i+=1){
        if(selectList[i].selectIndex !== listIndex){
          newList.push(selectList[i]);
        }
      }
      setMyListInfo(data =>({...data,'checked':false}));
      dispatch(changeSelectColistList({selectList:newList}));
    }
  }
  const changeSelectInfo =(value) =>{
    let inputList = {};
    let changeIndex= 0;
    setInputInfo(value)
    for(let i =0 ;i<selectList.length;i+=1){
      if(selectList[i].selectIndex === listIndex){
        inputList = {...selectList[i]}
        changeIndex = i
      }
    }
    inputList.selectVal = value;
    selectList[changeIndex] = inputList
    dispatch(changeSelectColistList({selectList}));
  }
  const changeInput=(e)=>{
   let val = e.target.value.replace(/[^\d.]/g,'')
   if(val-Number(numberFormat(myListInfo.showBalance,'',6)) >0){
    val = numberFormat(myListInfo.showBalance,'',6)
   }
    changeSelectInfo(val);
  }
  const setMaxChange=(e)=>{
    e.stopPropagation();
    changeSelectInfo(myListInfo.balance);
  }
  return (
    <FormGroup className='formGroup'>
      <FormControlLabel
        control={
          <Checkbox 
            onChange={(e)=>{handleChange(e)}}
            checked={myListInfo.checked}
            sx={{ 
              '& .MuiSvgIcon-root':{"fontSize": 16,"color":'#000'}, 
            }}
          />
        }
        label={
          <div className='coinInfo'>
            <div className='leftCoinOnfo'>
              <img className='listicon' src={myListInfo.logoURI} alt="" />
              <div className='group'>
                <p className='name'>{myListInfo.name}</p>
                <p className='desc'>Balance {numberFormat(myListInfo.showBalance,'',6)} (${coinPrice[myListInfo.coinId] && coinPrice[myListInfo.coinId].usd ?numberFormat(coinPrice[myListInfo.coinId].usd):0})</p>
              </div>
            </div>
            <div className='rightCoinInfo' style={{'visibility':myListInfo && myListInfo.checked?'visible':'hidden'}}>
              <input type="text" 
                onChange={(e)=>changeInput(e)} 
                value ={inputInfo}  
                className='numberInput' 
              />
            </div>
          </div>
        }
      />
      <div 
        style={{'visibility':myListInfo && myListInfo.checked?'visible':'hidden'}}
        className='selectMax'
        role="button"
        tabIndex = {-1}
        onClick = {(e)=>{setMaxChange(e)}}
        onKeyDown = {()=>{}}
        >MAX</div>
      </FormGroup>
      
  )
  
}
MyCoinListComponents.propTypes = {
  listInfo:PropTypes.oneOfType([PropTypes.object]),
  listIndex:PropTypes.number,
}
export default MyCoinListComponents
