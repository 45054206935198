import * as React from 'react';
import PropTypes from 'prop-types';
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { connect } from '../assets/js/common';
import './connectDialog.scss';
import closedialog from '../assets/img/closedialog.png';
import metamaskImage from '../assets/img/metamask.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{}} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img height="28px" className="closeImg" src={closedialog} alt="" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ConnectDialog({
  isVisible = false,
  handleClose = () => {},
}) {
  const dispatch = useDispatch();
  return (
    <div className="ConnectDialog">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isVisible}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{
            fontSize: '25px',
          }}
        >
          Connect Wallet
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div
            style={{
              width: '300px',
              marginBottom: '20px',
              marginTop: '24px',
              marginLeft: '24px',
              marginRight: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #979797',
              paddingLeft: '24px',
              paddingRight: '24px',
              cursor: 'pointer',
            }}
            role="button"
            onClick={() => {
              connect(dispatch);
              handleClose();
            }}
          >
            <img src={metamaskImage} height="46px" alt="" />
            <div
              style={{
                fontSize: '20px',
                fontWeight: 'normal',
                lineHeight: '28px',
                paddingLeft: '12px',
              }}
            >
              MetaMask
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

ConnectDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
