import React from 'react';
import Link from '@mui/material/Link';
import copy from 'copy-to-clipboard';
import PropTypes, { shape } from 'prop-types';
import discordimg from '../../../assets/img/discord.png'
import twitterimg from '../../../assets/img/icon_twitter.png'
import trophyfill from '../../../assets/img/trophy-fill.png'
import webshare from '../../../assets/img/webshare.png'
import './projectInfo.scss'
import {numberFormat} from '../../../utils/tools'
import bigrz from '../../../assets/img/bigrz.png'
import shares from '../../../assets/img/shares.png'
import coiniconbg from '../../../assets/img/coiniconbg.png'
import noDataFound from "../../../assets/img/noDataFound.png"
import createNotification from '../../../utils/toast';

function ProjectInfoComponents(props){
  const {projectInfo}  = props
  function copyClipboard(){
    const copyUrl = window.location.href;
    copy(copyUrl);
    createNotification('copy success');
  }
  return (
    <section className='projectInfo'>
        <div className='projectInfoLeft'>
          <img className='projectLogo' src={projectInfo.imageUrl?projectInfo.imageUrl:noDataFound} alt="" />
          <div className='detailInfo'>
            <h3 className='projectTitle'>
              <span>{projectInfo.name}</span>
              {
                projectInfo.safelistRequestStatus === 'verified'?
                <img className='bigrz' src={bigrz} alt="" />:
                ""
              }
              
            </h3>
            <p className='projectDesc'>{projectInfo.description}</p>
            <div className='imgList'>
              <Link href={projectInfo.discordUrl} target="_blank" className = "hreflink">
                <img  src={discordimg} alt="" />
              </Link>
              <Link className = "hreflink" target="_blank" href={`https://twitter.com/${projectInfo.twitterUsername}`}>
                <img  src={twitterimg} alt="" />
              </Link>
              <Link className = "hreflink" target="_blank" href={projectInfo.externalUrl}>
                <img  src={trophyfill} alt="" />
              </Link>
              <div className = "hreflink"
                role= "button"
                tabIndex= {-1}
                onKeyDown = {()=>{}}
                onClick = {()=>copyClipboard()}
              >
                <img  src={webshare} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className='projectInfoRight'>
          <ul className='numList'>
            <li>
              <p className='num'>{numberFormat(projectInfo.stats.totalSales,'K')}</p>
              <p className='listName'>items</p>
            </li>
            <li>
              <p className='num'>{numberFormat(projectInfo.stats.numOwners,'K')}</p>
              <p className='listName'>owners</p>
            </li>
            <li>
              <p className='num'>
                <img className='iconlogo' src={coiniconbg} alt="" />
                <span>{numberFormat(projectInfo.stats.floorPrice,'K')}</span>
              </p>
              <p className='listName'>floor price</p>
            </li>
            <li>
              <p className='num'>
                <img className='iconlogo' src={coiniconbg} alt="" />
                <span>{numberFormat(projectInfo.stats.totalVolume,'K')}</span>
              </p>
              <p className='listName'>volume traded</p>
            </li>
          </ul>
          <div className='link'>
            {
              (projectInfo.primaryAssetContracts && projectInfo.primaryAssetContracts.length > 0)?
              <Link target="_blank" href={`https://etherscan.io/address/${projectInfo.primaryAssetContracts[0].address}`} className='linkInfo mr44'>
                <span className='linkActive mr2'>Contract</span>
                <span>{projectInfo.primaryAssetContracts[0].address.slice(0,6)}</span>
                <img className='linkShare' src={shares} alt="" />
              </Link>
              :""
            }
            <Link target="_blank" href={`https://opensea.io/collection/${projectInfo.slug}`} className='linkInfo'>
              <span className='mr2'>Listed on</span>
              <span className='linkActive'>Opensea</span>
              <img className='linkShare' src={shares} alt="" />
            </Link>
          </div>
        </div>
      </section>
  )
}
ProjectInfoComponents.propTypes = {
  projectInfo:PropTypes.shape({
    imageUrl:PropTypes.string,
    name:PropTypes.string,
    safelistRequestStatus:PropTypes.string,
    description:PropTypes.string,
    discordUrl:PropTypes.string,
    twitterUsername:PropTypes.string,
    externalUrl:PropTypes.string,
    stats:PropTypes.shape({
      totalSales:PropTypes.number,
      numOwners:PropTypes.number,
      floorPrice:PropTypes.number,
      totalVolume:PropTypes.number,
    }),

    primaryAssetContracts:PropTypes.arrayOf(shape({
      address:PropTypes.string,
    })),
    slug:PropTypes.string,
  }),
}
export default ProjectInfoComponents
