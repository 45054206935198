import React, { useState } from 'react';
import PropTypes, { shape } from 'prop-types';
import detailAddMore from "../../../assets/img/detailAddMore.png"
import './properties.scss'

function PropertiesComponents(props){
  const {traits} = props;
  const [showAddMore,setShowAddMore] = useState(true);
  function rederTraitsList(item,index){
    return <li key = {index}>
      <p className='dete'>{item.traitType}</p>
      <p className='unshaven'>{item.value}</p>
      <p className='next'>{item.traitCount} have this</p>
    </li> 
  }
  return (
    <section className='properties'>
      <h3 className='title'>Properties</h3>
      <ul className='ulList' style={{'maxHeight':showAddMore?'228px':'20000px'}}>
        {
          traits.map((item,index) =>rederTraitsList(item,index))
        }
      </ul>
      {
        traits && traits.length > 10 && showAddMore?
        <div 
          className='addMore'
          role="button"
          tabIndex={-1}
          onKeyDown = {()=>{}}
          onClick = {()=>setShowAddMore(false)}
        >
          <img className='addMoreImg' src={detailAddMore} alt="" />
        </div>
        :''
      }
      
    </section>
  )
}
PropertiesComponents.propTypes = {
  traits:PropTypes.arrayOf(shape({
    traitType:PropTypes.string,
    value:PropTypes.string,
    traitCount:PropTypes.number,
  })).isRequired,
}
export default PropertiesComponents
