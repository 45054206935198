import arbitrum from '../assets/img/arbitrum.png';
import avalanche from '../assets/img/avalanche.png';
import binance from '../assets/img/binance.png';
import ethereum from '../assets/img/ethereum.png';
import fantom from '../assets/img/fantom.png';
import optimism from '../assets/img/optimism.png';
import polygon from '../assets/img/polygon.png';

const chainListTest = [
  {
    value: '0x4',
    label: 'Rinkeby Testnet',
    chainId: '0x4',
    chainName: 'Rinkeby Testnet',
    rpcUrls: ['https://rinkeby.infura.io/v3/'],
    blockExplorerUrls: ['https://rinkeby.etherscan.io/'],
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  {
    value: '0x61',
    label: 'BSC Testnet',
    chainId: '0x61',
    chainName: 'BSC Testnet',
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
    nativeCurrency: {
      name: 'Binance',
      symbol: 'BNB',
      decimals: 18,
    },
  },
  {
    value: '0xa869',
    label: 'Avalanche FUJI',
    chainId: '0xa869',
    chainName: 'Avalanche FUJI',
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://testnet.snowtrace.io/'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
  },
  {
    value: '0x13881',
    label: 'Polygon Testnet',
    chainId: '0x13881',
    chainName: 'Polygon Testnet',
    rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
    nativeCurrency: {
      name: 'Polygon',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  {
    value: '0x66eeb',
    label: 'Arbitrum Testnet',
    chainId: '0x66eeb',
    chainName: 'Arbitrum Testnet',
    rpcUrls: ['https://rinkeby.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://testnet.arbiscan.io/'],
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  {
    value: '0x45',
    label: 'Optimistic Kovan',
    chainId: '0x45',
    chainName: 'Optimistic Kovan',
    rpcUrls: ['https://kovan.optimism.io'],
    blockExplorerUrls: ['https://kovan-optimistic.etherscan.io/'],
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  {
    value: '0xfa2',
    label: 'Fantom testnet',
    chainId: '0xfa2',
    chainName: 'Fantom testnet',
    rpcUrls: ['https://rpc.testnet.fantom.network/'],
    blockExplorerUrls: ['https://testnet.ftmscan.com/'],
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
    },
  },
];
const chainListMain = [
  {
    value: '0x1',
    label: 'Ethereum',
    chainId: '0x1',
    chainName: 'Ethereum',
    rpcUrls: ['https://mainnet.infura.io/v3/'],
    blockExplorerUrls: ['https://etherscan.io'],
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  {
    value: '0x38',
    label: 'BSC',
    chainId: '0x38',
    chainName: 'BSC',
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com'],
    nativeCurrency: {
      name: 'Binance',
      symbol: 'BNB',
      decimals: 18,
    },
  },
  {
    value: '0xa86a',
    label: 'Avalanche',
    chainId: '0xa86a',
    chainName: 'Avalanche',
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io/'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
  },
  {
    value: '0x89',
    label: 'Polygon',
    chainId: '0x89',
    chainName: 'Polygon',
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/'],
    nativeCurrency: {
      name: 'Polygon',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  {
    value: '0xa4b1',
    label: 'Arbitrum',
    chainId: '0xa4b1',
    chainName: 'Arbitrum',
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io/'],
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  {
    value: '0xa',
    label: 'Optimism',
    chainId: '0xa',
    chainName: 'Optimism',
    rpcUrls: ['https://mainnet.optimism.io'],
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  {
    value: '0xfa',
    label: 'Fantom',
    chainId: '0xfa',
    chainName: 'Fantom',
    rpcUrls: ['https://rpc.ftm.tools/'],
    blockExplorerUrls: ['https://ftmscan.com/'],
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
    },
  },
];
const chainSimpleInfoTest = {
  '0x4': {
    name: 'Rinkeby Testnet',
    logoName: ethereum,
    address: '0x27748f85afdc5A3094AC282199cCF9BE61CC290a',
    chainCoinId: 'ethereum',
    blockExplorerUrls:'https://rinkeby.etherscan.io/',
  },
  '0x61': {
    name: 'BSC Testnet',
    logoName: binance,
    address: '',
    blockExplorerUrls:'https://testnet.bscscan.com/',
  },
  '0xa869': {
    name: 'Avalanche FUJI',
    logoName: avalanche,
    address: '0x1fC3d88ba87F72Ef3F2A96750851f11ECF62018f',
    chainCoinId: 'avalanche-2',
    blockExplorerUrls:'https://testnet.snowtrace.io/',
  },
  '0x13881': {
    name: 'Polygon Testnet',
    logoName: polygon,
    address: '',
    blockExplorerUrls:'https://mumbai.polygonscan.com/',
  },
  '0x66eeb': {
    name: 'Arbitrum Testnet',
    logoName: arbitrum,
    address: '',
    blockExplorerUrls:'https://testnet.arbiscan.io/',
  },
  '0x45': {
    name: 'Optimistic Kovan',
    logoName: optimism,
    address: '',
    blockExplorerUrls:'https://kovan-optimistic.etherscan.io/',
  },
  '0xfa2': {
    name: 'Fantom testnet',
    logoName: fantom,
    address: '0x0276414d662d9903F010c819B0A5b62f9b403D58',
    chainCoinId: 'fantom',
    blockExplorerUrls:'https://testnet.ftmscan.com/',
  },
};
const chainSimpleInfoMain = {
  '0x1': {
    name: 'Ethereum',
    logoName: ethereum,
    address: '0x64f07081Fc51Ec7859DF57cBD9B5d110c9b268d1',
    chainCoinId: 'ethereum',
    blockExplorerUrls:'https://etherscan.io/',
  },
  '0x38': {
    name: 'BSC',
    logoName: binance,
    address: '0xdE216A5ea07FE391739239839f317fe3743D9A96',
    chainCoinId: 'binancecoin',
    blockExplorerUrls:'https://bscscan.com/',
  },
  '0xa86a': {
    name: 'Avalanche',
    logoName: avalanche,
    address: '0xb1Fa469CC57565707c489b188785eB88081104a7',
    chainCoinId: 'avalanche-2',
    blockExplorerUrls:'https://snowtrace.io/',
  },
  '0x89': {
    name: 'Polygon',
    logoName: polygon,
    address: '0xa04978Ed7aD53210FAae0b2EeBF7363ae5c0a5C5',
    chainCoinId: 'matic-network',
    blockExplorerUrls:'https://polygonscan.com/',
  },
  '0xa4b1': {
    name: 'Arbitrum',
    logoName: arbitrum,
    address: '0xA918EF1959E7DC8449f0BC0be634fF40f9574391',
    chainCoinId: 'ethereum',
    blockExplorerUrls:'https://arbiscan.io/',
  },
  '0xa': {
    name: 'Optimism',
    logoName: optimism,
    address: 'ethereum',
    chainCoinId: '0x9256Bf00Db8B22fe657bBF6fD2020879f79D8e6d',
    blockExplorerUrls:'https://optimistic.etherscan.io/',
  },
  '0xfa': {
    name: 'Fantom',
    logoName: fantom,
    address: '0x7Dc0A1B4FA2Cb8bB78c78BA7F2D87ebC8c2Cdaf8',
    chainCoinId: 'fantom',
    blockExplorerUrls:'https://ftmscan.com/',
  },
};
const getChainInfo = () => {
  if (process.env.REACT_APP_STAGE === 'dev') {
    return chainListTest;
  }
  return chainListMain;
};
const getSimpleChainInfo = () => {
  if (process.env.REACT_APP_STAGE === 'dev') {
    return chainSimpleInfoTest;
  }
  return chainSimpleInfoMain;
};
export { getChainInfo, getSimpleChainInfo };
