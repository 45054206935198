import { configureStore} from "@reduxjs/toolkit";
import {walletSlice} from "./actions/wallet";
import {authSlice} from "./actions/auth";
import {projectSlice} from "./actions/project";
import {cartSlice} from "./actions/cart"
import {ethPriceSlice} from "./actions/ethPrice"
import {coinListPriceSlice} from "./actions/coinListPrice"

const store = configureStore({
  reducer:{
    'wallet':walletSlice.reducer,
    'auth':authSlice.reducer,
    'project':projectSlice.reducer,
    'cart':cartSlice.reducer,
    'ethPrice':ethPriceSlice.reducer,
    'coinListPrice':coinListPriceSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
export default store
