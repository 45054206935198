import { ethers,utils } from 'ethers'
import routerAddress from "../config/uniSwapRouter"
import ERC20_ABI from '../abi/ERC20ABI'
import UNISWAPABI from '../abi/UNISWAPABI'
import stargateRouterList from "../config/stargateRouter"
// import {getAllUsdcCartFee} from "../assets/js/common"
import {isLocalChain} from './tools'
import localTradeAddress from '../config/localTrade'

const getTokenInfo=(selectTokenListTokens,signer,address,provider)=>{
  const tokenlist = selectTokenListTokens;
  const promiseAll = [];
  for(let i = 0;i<tokenlist.length;i+=1){
    if(tokenlist[i].address === '0x'){
      promiseAll.push(provider.getBalance(address).then((response) => {
        // console.log(response)
        tokenlist[i].balance =utils.formatUnits(response,tokenlist[i].decimals)
        tokenlist[i].showBalance =utils.formatUnits(response,tokenlist[i].decimals)
      }).catch(()=>{
        // console.log(e);
      }))
    }else{
      const erc20 = new ethers.Contract(tokenlist[i].address, ERC20_ABI, signer)
      promiseAll.push(erc20.balanceOf(address).then((response) => {
        tokenlist[i].balance =utils.formatUnits(response,tokenlist[i].decimals)
        tokenlist[i].showBalance =utils.formatUnits(response,tokenlist[i].decimals)
      }).catch(()=>{
        // console.log(e);
      }))
    }
  }
  return Promise.all(promiseAll).then(()=>tokenlist);
}
const getRealTimePrice = (selectCoinList,signer,chainId)=>{
  
  let poolAddressData = stargateRouterList[`chain${chainId}`].poolAddress
  const amountAll = []
  if(isLocalChain(chainId)){
    poolAddressData = localTradeAddress[`chain${chainId}`]
  }
  for(let i= 0;i<selectCoinList.length;i+=1){
    const uniswapAmount = new ethers.Contract(routerAddress[`chain${chainId}`], UNISWAPABI, signer)
    if(selectCoinList[i].selectInfo.address !== "0x"){
      if( selectCoinList[i].selectInfo.address === poolAddressData){
        amountAll.push(utils.parseUnits(selectCoinList[i].selectVal,6))
      }else{
        amountAll.push(uniswapAmount.getAmountsOut(utils.parseUnits(selectCoinList[i].selectVal,selectCoinList[i].selectInfo.decimals),[selectCoinList[i].selectInfo.address,poolAddressData]).then((res)=>res[1]))
      }
      
    }else if(isLocalChain(chainId)){
      amountAll.push(utils.parseUnits(selectCoinList[i].selectVal,18))
    }else{
      amountAll.push(uniswapAmount.getAmountsOut(utils.parseUnits(selectCoinList[i].selectVal,selectCoinList[i].selectInfo.decimals),[selectCoinList[i].selectInfo.replaceAddress,poolAddressData]).then((res)=>res[1]))
    }
  }
  
  return Promise.all(amountAll).then((val)=>{
    const newList = [];
    for(let i = 0;i<val.length;i+=1){
      if(isLocalChain(chainId)){
        // const info = utils.formatUnits(val[i],18)
        newList.push(val[i]);
      }else{
        // const info = utils.formatUnits(val[i],6)
        newList.push(val[i]);
      }
      
    }
    return newList
  })
  
}
export {
  getTokenInfo,
  getRealTimePrice,
}
