import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Select from "react-select";
import {getCollectionInfo,getCollectionAssetList,getCollectionEventList} from '../../services/project.serves'
import ProjectInfoComponents from './components/projectInfo'
import ProjectSliderComponents from './components/projectSlider'
import searchIcon from '../../assets/img/searchIcon.png'
import ProjetSynopsisComponents from './components/projetSynopsis'
import ActivityTableComponents from '../../components/activityTable'
import NoDataComponents from '../../components/noData'
import LoadingComponents from "../../components/loading"
import './main.scss'

const selectTranis = {};
let searchprice = {}
let status = []
let currentPage = 1

function ProjectMain(){
  let InputChangeTiger = null
  const{slug} = useParams();
  const [apisuccess,setapisuccess] = useState(false);
  const [totalNumber,setTotalNumber] = useState(0);
  const [assetItemList,setAssetItemList] = useState([])
  const [collectInfo,setCollectInfo] = useState({
    paymentTokens:{},
    traits:{},
  });
  const [selectTab,setselectTab] = useState(1);
  const [cursor,setCursor] = useState('');
  const [lastPage,setLastPage] = useState(false);
  const [assactivityList,setAssactivityList] = useState([])
  const [canclick,setCanclick] = useState(true)
  const [currentPrice, setCurrentPrice] = useState("asc");
  const [searchText,setSearchText] = useState('');
  const [showLoading,setShowLoading] = useState(true)
  function clearSearch(){
    searchprice = {}
    status = []
    currentPage = 1
  }
  function assetList(){
    getCollectionAssetList({
      slug,
      page:currentPage,
      sort:{
        price:currentPrice,
      },
      filters:{
        searchText,
        traits:selectTranis,
        price:searchprice,
      },
      status,
      size:12,
    },(response)=>{
      setShowLoading(false);
      setAssetItemList(response.items);
      setTotalNumber(response.total);
    })
  }
  function activityList(){
    const params = {
      slug,
    }
    if(cursor) params.cursor = cursor
    getCollectionEventList(params,(response)=>{
      setCursor(response.next)
      setCanclick(true)
      setShowLoading(false);
      if(response.next){
        setLastPage(false);
      }else{
        setLastPage(true);
      }
      setAssactivityList(data=>{
        const newdata = data.concat(response.assetEvents)
        return newdata
      })
    })
  }
  function changeTab (val){
    if(val === selectTab) return
    setselectTab(val);
    setShowLoading(true);
    if(val === 1){
      clearSearch();
      assetList();
    }else{
      setLastPage(false)
      setCursor('')
      setAssactivityList([]);
      activityList();
    }
    
  }
  useEffect(()=>{
    getCollectionInfo({slug},(response)=>{
      const resData = {...response}
      if(resData.paymentTokens && resData.paymentTokens.length>0){
        for(let i=0;i<resData.paymentTokens.length;i+=1){
          resData.paymentTokens[i].value = resData.paymentTokens[i].symbol;
          resData.paymentTokens[i].label = resData.paymentTokens[i].symbol;
          
        }
      }
      setCollectInfo(resData)
      setapisuccess(true)
    })
    clearSearch();
    setselectTab(1)
  },[slug])
  useEffect(()=>{
    assetList();  
  },[currentPrice,searchText,slug])
  function InputChange(e){
    if(InputChangeTiger) clearTimeout(InputChangeTiger);
    InputChangeTiger = setTimeout(()=>{
      setSearchText(e.target.value);
    },500)
  }
  const [selectCurrentPriceLabel, setSelectCurrentPriceLabel] = useState("Price: Low to High");
  const options = [
    { value: "asc", label: "Price: Low to High" },
    { value: "desc", label: "Price: High to Low" },
  ];

  const handleChange = (selectedOption) => {
    setCurrentPrice(selectedOption.value);
    setSelectCurrentPriceLabel(selectedOption.label)
  };
  
  const changeSelect =(checked,key,trankey)=>{
    if(checked){
      if(!selectTranis[key]){
        selectTranis[key] = []
      }
      selectTranis[key].push(trankey);
    }else{
      selectTranis[key] = selectTranis[key].filter((item)=> item !== trankey);
    }
    currentPage = 1
    assetList();
  }
  const changeBuyBow =(isChecked)=>{
    if(isChecked){
      status = ["buy_now"]
    }else{
      status = []
    }
    currentPage = 1
    assetList()
  }
  const changeSearchprice=(price)=>{
    searchprice = price
    currentPage = 1
    assetList();
  }

  function renderListHeader(){
    return (
      <div className='listContentHeader'>
        <div className='tablesgroup'>
          <div 
            className={selectTab === 1?'items active':'items'} 
            role="button"
            tabIndex = {-1}
            onKeyDown = {()=>{}}
            onClick = {()=>changeTab(1)
          }
          >Items</div>
          <div 
            className={selectTab === 2?'items active':'items'} 
            role="button"
            tabIndex = {-2}
            onKeyDown = {()=>{}}
            onClick = {()=>changeTab(2)
            }
          >Activity</div>
        </div>
        {
          selectTab === 1?
          <div className='listSearchGroup'>
            <div className='boxgroup inputInfo'>
              <img className='search' src={searchIcon} alt="" />
              <input className='nameorIdInput' type="text" onChange={(e)=>{InputChange(e)}} placeholder='Name or ID' />
            </div>
            <Select className='boxgroup selectGroup' placeholder = {selectCurrentPriceLabel} value={currentPrice} onChange={handleChange} 
  // @ts-ignore
            options={options} />
          </div>
          :''
        }
      </div>
    )
  }
  function renderEventList(){
    let renderInfo;
    if(showLoading){
      renderInfo = <LoadingComponents />
    }else if (totalNumber > 0){
      renderInfo = 
      <div>
        <div className='listProject'>
          {
            assetItemList.map((item)=>
            <div key={item.id} className='projectInfoList'>
              <ProjetSynopsisComponents slug={slug} itemInfo = {item} />
            </div>
            )
          }
        </div>
        <div className='listpagina'>
          <Stack spacing={2} sx={{
            '& .MuiPagination-root':{
              'margin': '0 auto',
            },
          }}>
            <Pagination sx={{
              '&.MuiPagination-root .MuiPaginationItem-icon':{
                'width':20,
                'height':20,
              },
              '&.MuiPagination-root .MuiPaginationItem-root':{
                'fontSize':16,
              },

            }} count={Math.ceil(totalNumber/12)} variant="outlined" page ={currentPage} shape="rounded" onChange = {(event,pageNum)=>{
              currentPage = pageNum
              assetList();
            }} />
          </Stack>
        </div>
      </div>
    }else{
      renderInfo =  <NoDataComponents />
    }
    return (
      <div className='eventList'>
        {renderInfo}
      </div>
    )
  }
  function renderActivetyList(){
    let renderInfo;
    if(showLoading){
      renderInfo = <LoadingComponents />
    }else if(assactivityList && assactivityList.length > 0){
      renderInfo = <ActivityTableComponents activityList = {assactivityList} />
    }else{
      renderInfo = <NoDataComponents />
    }
    return (
      <div className='projectActiveInfo'>
        {renderInfo}
        {
          !lastPage && assactivityList && assactivityList.length > 0?
          <div className='addmores' 
            role="button"
            tabIndex = {-3}
            onKeyDown = {()=>{}}
            onClick={()=>{
              setCanclick(false)
              activityList()
            } 
          }>{canclick?'Load More':'Loading...'}</div>
          :''
        }
      </div>
    )
  }
  return (
    <div className='projectMainPage'>
      <div className='bannerImg'>
        <img src={collectInfo.bannerImageUrl} alt="" />
      </div>
      {
        apisuccess?
        <ProjectInfoComponents projectInfo = {collectInfo}/>
        :''
      }
      <section className='searchContent'>
        {
          collectInfo.paymentTokens && collectInfo.paymentTokens.length > 0?
          <ProjectSliderComponents 
            paymentTokens = {collectInfo.paymentTokens} 
            traits = {collectInfo.traits}
            childernChangeSelect = {changeSelect}
            childrenChangeSearchprice = {changeSearchprice}
            childrenChangeBuyBow = {changeBuyBow}

          />
          :<div className='Slider'> </div>
        }
        <div className='listContent'>
          {
            renderListHeader()
          }
          {
            selectTab===1?renderEventList():renderActivetyList()
          }
        </div>
      </section>
    </div>
  )
}
export default ProjectMain
