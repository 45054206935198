import {requestGet,otherSystemApi} from '../utils/request'

function getTopCollection(params,callback){
  requestGet('/v1/home/top',params,(response)=>{
    callback(response)
  })
}
function getDiscovery(params,callback){
  requestGet('/v1/home/discovery',params,(response)=>{
    callback(response)
  })
}
// function getCoinList(params,callback){
//   requestGet('https://api.coingecko.com/api/v3/coins/list',{},(response)=>{
//     callback(response)
//   })
// }
function getCoinPrice(params,callback){
  otherSystemApi('https://api.coingecko.com/api/v3/simple/price',params,(response)=>{
    callback(response)
  })
}

export {
  getTopCollection,
  getDiscovery,
  getCoinPrice,
  // getCoinList,
} 
