import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import './discovery.scss';
import {getDiscovery} from "../../../services/index.services"
import indexleft from '../../../assets/img/indexleft.png'
import indexright from '../../../assets/img/indexright.png'
import bigrz from '../../../assets/img/bigrz.png'
import discoverImg from "../../../config/discoverImg"

function DiscoveryComponents(){
  const [discoveryList,setDiscoveryList] = useState([]);
  const [leftindex,setleftindex] = useState(0);
  const navigate = useNavigate();
  const stylelfet = {
    'transition':".4s ease transform",
    'transform':`translate3d(${4.15*leftindex}rem,0px,0px)`,
  }
  function sliderChange(val){
    if(val === 'add'){
      
      if(Number(-leftindex) >= Number(discoveryList.length-4)) return
      setleftindex(leftindex-1);
    }else{
      if(Number(-leftindex) <= 0 ) return
      setleftindex(leftindex+1);
    }
  }
  const goNextProject=(val)=>{
    navigate(`/project/main/${val}`)
  }
  useEffect(()=>{
    getDiscovery({},(data)=>{
      setDiscoveryList(newData =>{
        const setNewData = newData.concat(data)
        return setNewData
      })
    });
  },[])

  function renderOneDiscovery(item){
    return <div key = {item.id} 
      className='discover-content' 
      role='button'
      tabIndex = {-2}
      onKeyDown = {()=>{}}
      onClick={()=>goNextProject(item.slug)}>
      <div className='fmall'>
        <img className='fmImg' src={item.bannerImageUrl} alt="" />
      </div>
      
      <div className='detaGroup'>
        <h3 className='rqi'>{moment(item.launchDate).format('DD')}</h3>
        <p className='mouth'>{moment(item.launchDate).format('MMM Do YY').substring(0,3)}</p>
      </div>
      <img className='smallLogo' src={discoverImg[item.marketplace]} alt="" />
      <div className='mLogo'>
        <img className='logo' src={item.imageUrl} alt="" />
      </div>
      <h3 className='title'>
        <span>{item.name}</span>
        {
          item.safelistRequestStatus === 'verified'?
          <img className='rz' src={bigrz} alt="" />
          :''
        }
      </h3>
    </div>
  }
  return (
    <section className='discovery'>
      <h2>Discovery</h2>
      <div className='discovery-listcom'>
        <div 
          className='discovery-icon'
        >
          <div 
            role="button"
            className='disimgcom'
            tabIndex = {-1}
            onKeyDown={()=>{}}
            onClick={()=>{
              sliderChange('less')
            }}
          >
            <img className='disimgcom' src={indexleft} alt="left" />
          </div>
        </div>
        <div className='listContent'>
          <div className='showlist' style={stylelfet}>
            {
              discoveryList.map((item,index)=>renderOneDiscovery(item,index))
            }
          </div>
        </div>
        <div className='discovery-icon'>
          <div className='disimgcom'
            role="button"
            tabIndex = {-2}
            onKeyDown={()=>{}}
            onClick={()=>{
              sliderChange('add')
            }}
          >
            <img className='disimgcom' src={indexright} alt="right" />
          </div>
        </div>
      </div>
    </section>
  )
}
export default DiscoveryComponents
