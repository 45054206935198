import React from 'react';
import shoppingCart from '../../../assets/img/shoppingCart.png';
import currencyExchange from '../../../assets/img/currencyExchange.png';
import exchangeImg from '../../../assets/img/exchangeImg.png';
import lookRare from '../../../assets/img/lookRare.png';
import magicEden from '../../../assets/img/magicEden.png';
import opensea from '../../../assets/img/opensea.png';
// import paintSwap from '../../../assets/img/paintSwap.png';
// import rarible from '../../../assets/img/rarible.png';
import sj from '../../../assets/img/sj.png';
// import SuperRare from '../../../assets/img/SuperRare.png';
import x2y2 from '../../../assets/img/x2y2.png';
import NFTX from "../../../assets/img/NFTX.png"
import './introduction.scss'

function IntroductionComponents(){
  return (
    <section className='introduction '>
      <div className='introduction-left'>
        <h2>The 1st omnichain (cross-chain) NFTs Aggregator</h2>
        <div className='pinfo'>
          <img src={shoppingCart} alt="" />
          <p>Discover and buy NFTs across all marketplaces.</p>
        </div>
        <div className='pinfo'>
          <img src={exchangeImg} alt="" />
          <p>Buy cross-chain NFTs in one transaction.</p>
        </div>
        <div className='pinfo'>
          <img src={currencyExchange} alt="" />
          <p>Pay with any chain, any token.</p>
        </div>
        <div className='pinfo'>
          <img src={sj} alt="" />
          <p>All analytics in one place.</p>
        </div>
      </div>
      <ul className='introduction-right'>
        <li>
          <img src={opensea} alt="opensea" />
          <p className='active'>OpenSea</p>
        </li>
        <li>
          <img src={lookRare} alt="LookRare" />
          <p>LookRare</p>
        </li>
        <li>
          <img src={x2y2} alt="X2Y2" />
          <p>X2Y2</p>
        </li>
        <li>
          <img src={NFTX} alt="NFTX" />
          <p>NFTX</p>
        </li>
        <li>
          <img src={magicEden} alt="MagicEden" />
          <p>MagicEden</p>
        </li>
        {/* <li>
          <img src={paintSwap} alt="PaintSwap" />
          <p>PaintSwap</p>
        </li> */}
        {/* <li>
          <img src={rarible} alt="Rarible" />
          <p>Rarible</p>
        </li>
        <li>
          <img src={SuperRare} alt="SuperRare" />
          <p>SuperRare</p>
        </li> */}
        
        
        <li className='more'>...</li>
      </ul>
    </section>
  )
}
export default IntroductionComponents
