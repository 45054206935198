import { ethers,BigNumber, utils } from 'ethers'
import getJsonRpcProvider from "../../config/jsonRpcProvider"
import { setProvider } from '../../actions/wallet';
import { userLogin } from '../../actions/auth';
import {getShoppingList} from "../../services/cart.services"
import { changeShoppingList } from '../../actions/cart';
import getAuth from '../../services/auth.services'
import createNotification from '../../utils/toast'
import {encodeOrder,randomSalt} from "../../utils/coder"
import targetInfo from "../../config/targetChainInfo"
import {isLocalChain} from '../../utils/tools'
import {endCodeFulfillAvailableAdvancedOrdersCalldata} from "../../utils/seaport"
import UNISWAPABI from "../../abi/UNISWAPABI"


async function EthToUsdc(ETHNum){
  const restETHNum = ETHNum.mul(BigNumber.from(1006)).div(BigNumber.from(1000))
  const targetData = targetInfo()
  const provider = new ethers.providers.JsonRpcProvider(getJsonRpcProvider());
  const swap = new ethers.Contract(
    targetData.swapRouterAddress,
    UNISWAPABI,
    provider
  );
  const amountsOut = await swap.getAmountsOut(
    restETHNum,
    [targetData.wethAddress, targetData.usdcAddress]
  );
  return amountsOut[1];
}

async function getAllUsdcCartFee(shoppinngList,otherFee,chainId){
  let allEth = BigNumber.from(0);
  if(shoppinngList && shoppinngList.length > 0){  
    for(let i=0;i<shoppinngList.length;i+=1){
      allEth = allEth.add(BigNumber.from(shoppinngList[i].currentPrice))
    }
  }
  if(otherFee){
    const addFee =allEth.add(BigNumber.from(otherFee))
    if(isLocalChain(chainId)){
      // const addFeeNum = utils.formatUnits(addFee,18)
      return addFee
    }
    const allUSDC =await EthToUsdc(addFee);
    return allUSDC
  }
  if(isLocalChain(chainId)){
    // const allEthNum = utils.formatUnits(allEth,18)
    return allEth
  }
  const allUSDC = await EthToUsdc(allEth);
  return allUSDC
  
}
function getAllCartFee(ethPrice,shoppinngList,otherFee){
  let allEth = BigNumber.from(0);
  if(shoppinngList && shoppinngList.length > 0){  
    for(let i=0;i<shoppinngList.length;i+=1){
      allEth = allEth.add(BigNumber.from(shoppinngList[i].currentPrice))
    }
  }
  if(otherFee){
    const addFee =Number(ethPrice*Number(utils.formatEther(allEth)))+Number(otherFee)
    return addFee
  }
  return ethPrice*Number(utils.formatEther(allEth))
}

function onConnect (address, provider, signer,dispatch) {
  dispatch(setProvider({ provider,signer}));
  getAuth(address,accessToken=>{
    dispatch(userLogin({accessToken,address}));
    getShoppingList((response)=>{
      dispatch(changeShoppingList({shoppinngList:response.assetList}))
    });
  })
};

async function getProvider (dispatch){
  const accounts = await window.ethereum.request({
    method: 'eth_requestAccounts',
  });
  const provider = new ethers.providers.Web3Provider(window.ethereum,"any");
  provider.on("network", (newNetwork, oldNetwork) => {
    // When a Provider makes its initial connection, it emits a "network"
    // event with a null oldNetwork along with the newNetwork. So, if the
    // oldNetwork exists, it represents a changing network
    if (oldNetwork) {
      sessionStorage.clear()
      window.location.reload();
    }
});
  const signer = provider.getSigner();
  onConnect(accounts[0],provider,signer,dispatch);
}
function addChain(dispatch,chainInfo) {
  // @ts-ignore
  window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: chainInfo?chainInfo.chainId:'',
        chainName: chainInfo?chainInfo.chainName:'',
        rpcUrls:chainInfo?chainInfo.rpcUrls:[],
      },
    ],
  });
  // getProvider(dispatch);
}

async function connect(dispatch,chainInfo={}) {
  // @ts-ignore
  if (!window.ethereum) {
    createNotification('Please install MetaMask','error');
    return;
  }
  // @ts-ignore
  if(chainInfo.chainId){
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainInfo.chainId }],
      });
      // getProvider(dispatch);
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          addChain(dispatch,chainInfo);
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  }else{
    getProvider(dispatch);
  }
}
function getOrderData(list,address){
  const orderEncode = []
  // const paymentToken = {
  //   tokenAddrs:[],
  //   amounts:[],
  // }
  // const seaportList = [];
  // let orderValue = BigNumber.from(0);
  const targetInfoes = targetInfo();
  for(let i=0;i<list.length;i+=1){
    if(list[i].sellOrders){
      const encodeData = encodeOrder(
        1,
        list[i].sellOrders[0].exchange,
        targetInfoes.address,
        list[i].sellOrders[0].maker.address,
        list[i].sellOrders[0].target,
        list[i].sellOrders[0].feeRecipient.address,
        list[i].sellOrders[0].makerRelayerFee,
        list[i].sellOrders[0].basePrice,
        `${Math.floor( new Date().getTime()/1000)+300}`,
        list[i].sellOrders[0].listingTime,
        list[i].sellOrders[0].expirationTime,
        randomSalt(),
        list[i].sellOrders[0].salt,
        list[i].sellOrders[0].v,
        list[i].sellOrders[0].r,
        list[i].sellOrders[0].s,
        address,
        list[i].creator.address,
        list[i].assetContract.address,
        list[i].tokenId,
        list[i].sellOrders[0].paymentTokenContract.address
      )
      orderEncode.push(encodeData);
    }else{
      // orderValue = orderValue.add(BigNumber.from(list[i].currentPrice))
      const listInfo = list[i].seaportSellOrders[0].protocolData.parameters;
      const parameters = {
        offerer:listInfo.offerer,
        zone:listInfo.zone,
        offer:listInfo.offer,
        consideration:listInfo.consideration,
        orderType:listInfo.orderType,
        startTime:BigNumber.from(listInfo.startTime),
        endTime:BigNumber.from(listInfo.endTime),
        zoneHash:listInfo.zoneHash,
        salt:BigNumber.from(listInfo.salt),
        conduitKey:listInfo.conduitKey,
        totalOriginalConsiderationItems:BigNumber.from(String(listInfo.totalOriginalConsiderationItems)),
      }
      const orderData= {
        parameters,
        numerator:1,
        denominator:1,
        signature:list[i].seaportSellOrders[0].protocolData.signature,
        extraData: "0x",
      }
      const newEncodeData = endCodeFulfillAvailableAdvancedOrdersCalldata([orderData],listInfo.consideration,address,BigNumber.from(list[i].currentPrice));
      orderEncode.push(newEncodeData);
      // seaportList.push(list[i])
    }
  }
  // if(seaportList && seaportList.length > 0){
  //   const advancedOrders = [];
    
  //   for(let i=0;i<seaportList.length;i+=1){
  //     const orderData= {
  //       parameters:seaportList[i].seaportSellOrders[0].protocolData.parameters,
  //       numerator:1,
  //       denominator:1,
  //       signature:seaportList[i].seaportSellOrders[0].protocolData.signature,
  //       extraData: "0x",
  //     }
  //     advancedOrders.push(orderData);
  //   }
  //   const newEncodeData = endCodeFulfillAvailableAdvancedOrdersCalldata(advancedOrders,address,orderValue)
  //   orderEncode.push(newEncodeData);
  // }
   
  const endData = {
    orderEncode,
    // paymentToken,
  }
  return endData
}
const mockOrder = () => {
  const targetInfoes = targetInfo();
  const order = encodeOrder(
    1,
    "0xdd54d660178b28f6033a953b0e55073cfa7e3744",
    targetInfoes.address,
    "0x7e9ab6b2f29a442c93e3a38e1348b4fcf957e86f",
    "0x45b594792a5cdc008d0de1c1d69faa3d16b3ddc1",
    "0x5b3256965e7c3cf26e11fcaf296dfc8807c01073",
    "250",
    "100000000000000",
    "1654171316",
    "1655120125",
    "1662896204",
    "48401954692480478738679020778491749653603270608579938638013019634546466938339",
    "3587581782368019406228045321747247067895891236287261478050787421579323052334",
    27,
    "0xaacf07d1e596f984f790282b50caf0f57f342f654d3476a2bba98b72333253e9",
    "0x00622e2a97b843a645d51ec9d385867f9422971d64da38f4e517e91f1b70aa6d",
    "0x244a807084a3eb9fd5fe88aa0b13aec8401577bd",
    "0x7e9ab6b2f29a442c93e3a38e1348b4fcf957e86f",
    "0x4b5acd5163f31863e548feee21527d3069c727cf",
    "288",
    ethers.constants.AddressZero
  );
  const endData = {
    orderEncode:[order],
  }
  return endData;
};
export {
  getAllCartFee,
  connect,
  onConnect,
  getOrderData,
  getAllUsdcCartFee,
  mockOrder,
}
