const stargateRouterList = {
  'chain0x4':{
    decimals:6,
    stargateChainId:10001,
    stargateRouterAddress:'0x82A0F5F531F9ce0df1DF5619f74a0d3fA31FF561',
    poolAddress:'0x1717A0D5C8705EE89A8aD6E808268D6A826C97A4',
    poolId:1,
  },
  'chain0x61':{
    stargateChainId:10002,
    decimals:18,
    stargateRouterAddress:'0xbB0f1be1E9CE9cB27EA5b0c3a85B7cc3381d8176',
    poolAddress:'0xF49E250aEB5abDf660d643583AdFd0be41464EfD',
    poolId:2,
  },
  'chain0xa869':{
    stargateChainId:10006,
    decimals:6,
    stargateRouterAddress:'0x13093E05Eb890dfA6DacecBdE51d24DabAb2Faa1',
    poolAddress:'0x4A0D1092E9df255cf95D72834Ea9255132782318',
    poolId:1,
  },
  'chain0x13881':{
    stargateChainId:10009,
    decimals:6,
    stargateRouterAddress:'0x817436a076060D158204d955E5403b6Ed0A5fac0',
    poolAddress:'0x742DfA5Aa70a8212857966D491D67B09Ce7D6ec7',
    poolId:1,
  },
  'chain0x66eeb':{
    stargateChainId:10010,
    decimals:6,
    stargateRouterAddress:'0x6701D9802aDF674E524053bd44AA83ef253efc41',
    poolAddress:'0x1EA8Fb2F671620767f41559b663b86B1365BBc3d',
    poolId:1,
  },
  'chain0x45':{
    stargateChainId:10011,
    decimals:6,
    stargateRouterAddress:'0xCC68641528B948642bDE1729805d6cf1DECB0B00',
    poolAddress:'0x567f39d9e6d02078F357658f498F80eF087059aa',
    poolId:1,
  },
  'chain0xfa2':{
    stargateChainId:10012,
    decimals:6,
    stargateRouterAddress:'0xa73b0a56B29aD790595763e71505FCa2c1abb77f',
    poolAddress:'0x076488D244A73DA4Fa843f5A8Cd91F655CA81a1e',
    poolId:1,
  },

  // mainnet
  'chain0x1':{
    decimals:6,
    stargateChainId:1,
    stargateRouterAddress:'0x8731d54E9D02c286767d56ac03e8037C07e01e98',
    poolAddress:'0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    poolId:1,
  },
  'chain0x38':{
    stargateChainId:2,
    decimals:18,
    stargateRouterAddress:'0x4a364f8c717cAAD9A442737Eb7b8A55cc6cf18D8',
    poolAddress:'0x55d398326f99059fF775485246999027B3197955',
    poolId:2,
  },
  'chain0xa86a':{
    stargateChainId:6,
    decimals:6,
    stargateRouterAddress:'0x45A01E4e04F14f7A4a6702c74187c5F6222033cd',
    poolAddress:'0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    poolId:1,
  },
  'chain0x89':{
    stargateChainId:9,
    decimals:6,
    stargateRouterAddress:'0x45A01E4e04F14f7A4a6702c74187c5F6222033cd',
    poolAddress:'0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    poolId:1,
  },
  'chain0xa4b1':{
    stargateChainId:10,
    decimals:6,
    stargateRouterAddress:'0x53Bf833A5d6c4ddA888F69c22C88C9f356a41614',
    poolAddress:'0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    poolId:1,
  },
  'chain0xa':{
    stargateChainId:11,
    decimals:6,
    stargateRouterAddress:'0xB0D502E938ed5f4df2E681fE6E419ff29631d62b',
    poolAddress:'0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    poolId:1,
  },
  'chain0xfa':{
    stargateChainId:12,
    decimals:6,
    stargateRouterAddress:'0xAf5191B0De278C7286d6C7CC6ab6BB8A73bA2Cd6',
    poolAddress:'0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    poolId:1,
  },
}
export default stargateRouterList
