import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import Link from '@mui/material/Link';
import {getHomeuserAssets,getUserEventList} from "../../services/user.services"
import DivisionLine from '../../components/divisionLine'
import userlogo from '../../assets/img/userlogo.png'
import shares from '../../assets/img/shares.png'
import SynopsisComponents from './components/synopsis'
import ActivityTableComponents from '../../components/activityTable'
import LoadingComponents from "../../components/loading"
import {formatAddress} from '../../utils/tools'
import {getSimpleChainInfo} from "../../config/chainList"
import NoDataComponents from '../../components/noData'
import './main.scss'

function UserMainPage(){
  const [selectTab,setselectTab] = useState(1);
  const [cursor,setCursor] = useState('');
  const [userAssets,setUserAssets]=useState([]);
  const [canclick,setCanclick] = useState(true)
  const [lastPage,setlastPage] = useState(false)
  const [userActivity,setUserActivity]=useState([]);
  const [isLoading,setIsLoading]=useState(true);
  const chainId  = useSelector(state => state.wallet.chainId)
  const address = useSelector((state) => state.auth.address);
  // const [hrefLink,setHrefLink] = useState('');
  
  const hrefBlockExplorerUrls =()=>{
    window.open(`${getSimpleChainInfo()[chainId].blockExplorerUrls}address/${address}`)
  }
  const userAssetsRender = ()=>{
    if(isLoading){
      return <LoadingComponents />
    }
    if(userAssets && userAssets.length > 0){
      return userAssets.map((item)=>
        <div className='content' key={item.id}>
          <SynopsisComponents itemInfo = {item}/>
        </div>
      )
    }
    return <NoDataComponents />
  }
  const userActivityRender =() =>{
    if(isLoading){
      return <LoadingComponents />
    }
    if(userActivity && userActivity.length > 0){
      return <ActivityTableComponents activityList = {userActivity}/>
    }
    return <NoDataComponents />
  }
  function getActivityList(val){
    if(!val) setIsLoading(true)
    getUserEventList({
      address,
      cursor,
    },(response)=>{
      setIsLoading(false)
      setCanclick(true);
      if(response.next) {
        setlastPage(false);
        setCursor(response.next)
      }else{
        setlastPage(true);
        setCursor('')
      }
      setUserActivity(data=> data.concat(response.assetEvents));
    });
  }
  function getuserAssets(val){
    if(!val){
      setIsLoading(true)
    }
    getHomeuserAssets({
      address,
      cursor,
    },(response)=>{
      setIsLoading(false);
      setCanclick(true);
      if(response.next) {
        setlastPage(false);
        setCursor(response.next)
      }else{
        setlastPage(true);
        setCursor('')
      }
      setUserAssets(data=> data.concat(response.assets));
    });
  }
  useEffect(()=>{
    if(address){
      getuserAssets();
    }
    
  },[address]);
  function changeTab (val){
    setselectTab(val);
    setCanclick(true);
    setlastPage(false);
    setCursor('')
    setUserActivity([]);
    setUserAssets([]);
    if(val === 1){
      getuserAssets();
    }else{
      getActivityList();
    }
  }
  return (
    <div className='userMainPage'>
      <DivisionLine />
      <div className='userInfo'>
        <img className='userLogo' src={userlogo} alt="" />
        <div className='infoGroup'>
          <p className='addressName'>{address?address.slice(0,6):''}</p>
          <div 
            className='addressInfo'
            role="button"
            tabIndex = {-1}
            onClick = {()=>{
              hrefBlockExplorerUrls()
            }}
            onKeyDown={()=>{}}
          >
              <span>{address?formatAddress(address):''}</span>
              <img className='shareAddress' src={shares} alt="" />
          </div>
        </div>
      </div>
      <div className='userTabs'>
        <div className='tablesgroup'>
          <div 
            role="button"
            tabIndex = {-2}
            onKeyDown = {()=>{}}
            className={selectTab === 1?'items active':'items'}
            onClick = {()=>changeTab(1)}
          >
            Owned
          </div>
          <div 
            role="button"
            tabIndex = {-3}
            onKeyDown = {()=>{}}
            className={selectTab === 2?'items active':'items'} 
            onClick = {()=>changeTab(2)}
          >
            Activity
          </div>
        </div>
        {/* <div className='listSearchGroup'>
          <div className='boxgroup inputInfo'>
            <img className='search' src={searchIcon} alt="" />
            <input className='nameorIdInput' type="text" placeholder='Name or ID' />
          </div>
          <div className='boxgroup'></div>
        </div> */}
      </div>
      {selectTab===1?
        <div className='UserListProject'>
          <div className='projectList'>
            {userAssetsRender()}
          </div>
          {
            !lastPage && userAssets && userAssets.length > 0?
            <div className='addmores' 
              role="button"
              tabIndex = {-4}
              onKeyDown = {()=>{}}
              onClick={()=>{
                if(!canclick) return
                setCanclick(false)
                getuserAssets('notFristPage')
              } 
            }>{canclick?'Load More':'Loading...'}</div>
            :''
          }
        </div>
        :
        <div className='activeInfo'>
          {userActivityRender()}
          {
            !lastPage && userActivity && userActivity.length > 0?
            <div className='addmores'
              role="button"
              tabIndex = {-5}
              onKeyDown = {()=>{}}
              onClick={()=>{
                if(!canclick) return
                setCanclick(false)
                getActivityList('noFristPage')
              } 
            }>{canclick?'Load More':'Loading...'}</div>
            :''
          }
        </div>
      }
    </div>
  )
}
export default UserMainPage
