import { createSlice } from '@reduxjs/toolkit'

const initialProjectState = {
  selectTranis: {}, 
  price: {},
}

export const projectSlice = createSlice({
  name: 'project',
  initialState: initialProjectState,
  reducers: {
    setSelectTranis:(state,action)=>{
      state.selectTranis = action.payload.selectTranis
    },
    setPrice:(state,action)=>{
      state.price = action.payload.price
    },
  },
})

export const { setSelectTranis,setPrice } = projectSlice.actions

// export default projectSlice
