import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { ethers, utils, BigNumber } from 'ethers';
import { encodePayload } from '../../../utils/coder';
import { numberFormat, isLocalChain } from '../../../utils/tools';
import STARGATEABI from '../../../abi/STARGATEABI';
import {
  getAllCartFee,
  getAllUsdcCartFee,
  getOrderData,
} from '../../../assets/js/common';
import { changePayAllMoney, changeShoppingList } from '../../../actions/cart';
import stargateRouterList from '../../../config/stargateRouter';
import targetInfo from '../../../config/targetChainInfo';
import { getRealTimePrice } from '../../../utils/getTokensInfo';
import { clearShoppingList } from '../../../services/cart.services';
import oswapCore from '../../../abi/OswapCore';
import ERC20_ABI from '../../../abi/ERC20ABI';
import { getSimpleChainInfo } from '../../../config/chainList';
import createNotification from '../../../utils/toast';
import './buyInfo.scss';

function BuyInfoComponents() {
  const ethPrice = useSelector((state) => state.ethPrice.price);
  const coinPrice = useSelector((state) => state.coinListPrice.priceAggregate);
  const [showAllmoney, setShowAllmoney] = useState(0);
  const shoppinngList = useSelector((state) => state.cart.shoppinngList);
  const chainId = useSelector((state) => state.wallet.chainId);
  const signer = useSelector((state) => state.wallet.signer);
  const address = useSelector((state) => state.auth.address);
  const [showLoading, setShowLoading] = useState(false);
  const targetInfoes = targetInfo();
  const [fee, setFee] = useState('0');
  const dispatch = useDispatch();
  const selectCoinList = Object.assign(
    [],
    useSelector((state) => state.cart.selectCoinList)
  );
  const navigate = useNavigate();
  const chainSimpleInfo = getSimpleChainInfo();
  const needApprovalList = [];
  const [crossBuyValue, setCrossBuyValue] = useState(0);
  const totalSelectedCoinsUSD = useSelector(
    (state) => state.cart.totalSelectedCoinsUSD
  );
  const payAllMoney = useSelector((state) => state.cart.payAllMoney);
  const [buyDisabled, setBuyDisabled] = useState(false);

  useEffect(() => {
    if (
      totalSelectedCoinsUSD &&
      totalSelectedCoinsUSD > 0 &&
      totalSelectedCoinsUSD >= payAllMoney
    ) {
      setBuyDisabled(false);
    } else {
      setBuyDisabled(true);
    }
  }, [totalSelectedCoinsUSD, payAllMoney]);

  let orderData = [];
  const showEndAllMoney = (addFee) => {
    setFee(addFee);
    const allPay = getAllCartFee(ethPrice, shoppinngList, addFee);
    dispatch(changePayAllMoney({ payAllMoney: allPay }));
    setShowAllmoney(allPay);
  };
  useEffect(() => {
    if (chainId && signer && shoppinngList && shoppinngList.length > 0) {
      if (isLocalChain(chainId)) {
        showEndAllMoney('0');
      } else {
        const chainCoinIdOne = chainSimpleInfo[chainId].chainCoinId;
        if (coinPrice[chainCoinIdOne] || (chainId === '0xa' && ethPrice)) {
          const stargateInfo = stargateRouterList[`chain${chainId}`];
          const dataGroup = getOrderData(shoppinngList, address);
          orderData = dataGroup.orderEncode;
          const stargateRouter = new ethers.Contract(
            stargateInfo.stargateRouterAddress,
            STARGATEABI,
            signer
          );
          const encodePayloadData = encodePayload(address, orderData);

          stargateRouter
            .quoteLayerZeroFee(
              targetInfoes.chainId,
              1,
              targetInfoes.address,
              encodePayloadData,
              {
                dstGasForCall: 500000,
                dstNativeAmount: ethers.BigNumber.from('0'),
                dstNativeAddr: '0x',
              }
            )
            .then((res) => {
              // const chainCoinIdOne = chainSimpleInfo[chainId].chainCoinId;
              let useFee;
              if (chainId === '0xa') {
                useFee = (ethPrice * Number(utils.formatUnits(res[0]))).toFixed(
                  2
                );
              } else {
                useFee = (
                  coinPrice[chainCoinIdOne].usd *
                  Number(utils.formatUnits(res[0]))
                ).toFixed(2);
              }
              setCrossBuyValue(Number(utils.formatUnits(res[0])) * 1.1);
              showEndAllMoney(useFee);
            });
        }
      }
    }
  }, [shoppinngList, signer, chainId, coinPrice]);
  const transactionedClearCart = () => {
    clearShoppingList((response) => {
      if (response) {
        createNotification('Submit Success');
        dispatch(changeShoppingList({ shoppinngList: [] }));
      }
    });
  };
  const transactionSuccess = (hash) => {
    setShowLoading(false);
    if (hash) {
      transactionedClearCart();
      navigate(`/`);
    }
  };
  const reportPurchase = (params) => {
    try {
      if (window.gtag) {
        window.gtag('event', 'purchase', params);
      }
    } catch (error) {
      /* eslint-disable */
      console.warn(error);
    }
  };
  async function transactionData() {
    const originalInfo = {
      tokenAddrs: [],
      amounts: [],
    };

    const oswapObject = new ethers.Contract(
      chainSimpleInfo[chainId].address,
      oswapCore,
      signer
    );

    if (shoppinngList && shoppinngList.length > 0) {
      const reportParams = {
        userAddress: address,
        cart: shoppinngList.map(({ tokenId, assetContract }) => ({
          tokenId,
          assetContract,
        })),
        sourceChain: chainId,
        payInTokens: selectCoinList,
        isSuccess: null,
        extra: {},
        timestamp: Date.now(),
      };
      let selectEthvalue = 0;
      const dataGroup = getOrderData(shoppinngList, address);
      if (selectCoinList && selectCoinList.length > 0) {
        if (isLocalChain(chainId)) {
          if (
            selectCoinList.length === 1 &&
            selectCoinList[0].selectInfo.address === '0x'
          ) {
            try {
              const res = await oswapObject.localBuyWithETH(
                dataGroup.orderEncode,
                {
                  value: ethers.utils.parseEther(selectCoinList[0].selectVal),
                }
              );
              transactionSuccess(res.hash);
              reportPurchase({ ...reportParams, isSuccess: true });
            } catch (error) {
              reportPurchase({
                ...reportParams,
                isSuccess: false,
                error: JSON.stringify(error),
              });
              setShowLoading(false);
              createNotification(
                'Oops! Looks like something went wrong.',
                'error'
              );
            }
          } else {
            for (let i = 0; i < selectCoinList.length; i += 1) {
              if (selectCoinList[i].selectInfo.address !== '0x') {
                originalInfo.tokenAddrs.push(
                  selectCoinList[i].selectInfo.address
                );
                originalInfo.amounts.push(
                  utils.parseUnits(
                    selectCoinList[i].selectVal,
                    selectCoinList[i].selectInfo.decimals
                  )
                );
              } else {
                selectEthvalue = selectCoinList[i].selectVal;
              }
            }
            try {
              const res = await oswapObject.localBuyWithERC20(
                originalInfo,
                dataGroup.orderEncode,
                {
                  value: ethers.utils.parseEther(String(selectEthvalue)),
                }
              );
              transactionSuccess(res.hash);
              reportPurchase({ ...reportParams, isSuccess: true });
            } catch (error){
              reportPurchase({
                ...reportParams,
                isSuccess: false,
                error: JSON.stringify(error),
              });
              setShowLoading(false);
              createNotification(
                'Oops! Looks like something went wrong.',
                'error'
              );
            }
          }
        } else {
          for (let i = 0; i < selectCoinList.length; i += 1) {
            if (selectCoinList[i].selectInfo.address === '0x') {
              originalInfo.tokenAddrs.push(ethers.constants.AddressZero);
              originalInfo.amounts.push(
                utils.parseUnits(
                  selectCoinList[i].selectVal,
                  selectCoinList[i].selectInfo.decimals
                )
              );
              setCrossBuyValue(
                crossBuyValue + Number(selectCoinList[i].selectVal)
              );
            } else {
              originalInfo.tokenAddrs.push(
                selectCoinList[i].selectInfo.address
              );
              originalInfo.amounts.push(
                utils.parseUnits(
                  selectCoinList[i].selectVal,
                  selectCoinList[i].selectInfo.decimals
                )
              );
            }
          }
          let total = await getAllUsdcCartFee(shoppinngList, 0, chainId);
          total = total.mul(BigNumber.from(1006)).div(BigNumber.from(1000));

          const feeObject = {
            amountOutMinDest: total,
            bridgeToken: stargateRouterList[`chain${chainId}`].poolAddress,
            srcPoolId: stargateRouterList[`chain${chainId}`].poolId,
            dstPoolId: targetInfoes.poolId,
          };
          try {
            const resData = await oswapObject.crossBuy(
              targetInfoes.chainId,
              originalInfo,
              feeObject,
              dataGroup.orderEncode,
              address,
              targetInfoes.address,
              Math.floor(new Date().getTime() / 1000) + 180,
              {
                value: ethers.utils.parseEther(String(crossBuyValue)),
              }
            );
            transactionSuccess(resData.hash);
            reportPurchase({ ...reportParams, isSuccess: true });
          } catch (error){
            reportPurchase({
              ...reportParams,
              isSuccess: false,
              error: JSON.stringify(error),
            });
            setShowLoading(false);
            createNotification(
              'Oops! Looks like something went wrong.',
              'error'
            );
          }
        }
      }
    }
  }
  const onApproval = (owner, spender, contractAddress) => {
    if (
      owner.toUpperCase() === address.toUpperCase() &&
      spender === chainSimpleInfo[chainId].address &&
      needApprovalList &&
      needApprovalList.indexOf(contractAddress) > -1
    ) {
      needApprovalList.splice(needApprovalList.indexOf(contractAddress), 1);
      if (needApprovalList.length === 0) {
        transactionData();
      }
    }
  };
  async function handleAporove() {
    const isApproval = [];
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < selectCoinList.length; i += 1) {
      if (selectCoinList[i].selectInfo.address !== '0x') {
        const contract = new ethers.Contract(
          selectCoinList[i].selectInfo.address,
          ERC20_ABI,
          signer
        );
        const res = await contract.allowance(
          address,
          chainSimpleInfo[chainId].address
        );
        // @ts-ignore
        if (BigNumber.from(selectCoinList[i].selectVal).gt(res)) {
          isApproval.push(true);
          needApprovalList.push(selectCoinList[i].selectInfo.address);
          try {
            await contract.approve(
              chainSimpleInfo[chainId].address,
              ethers.constants.MaxUint256
            );
            contract.on('Approval', (_owner, _spender) => {
              onApproval(
                _owner,
                _spender,
                selectCoinList[i].selectInfo.address
              );
            });
          } catch (error) {
            // console.log(error);
            setShowLoading(false);
            createNotification('approve error', 'error');
          }
        }
      }
    }
    if (isApproval.length === 0) {
      transactionData();
    }
  }
  async function getSelectCoinNum(val) {
    if (val && val.length > 0) {
      let allSelectCoinNum = BigNumber.from(0);
      for (let i = 0; i < val.length; i += 1) {
        allSelectCoinNum = allSelectCoinNum.add(val[i]);
      }

      let needPay = await getAllUsdcCartFee(shoppinngList, 0, chainId);
      if (isLocalChain(chainId)) {
        needPay = needPay.mul(BigNumber.from(1005));
      } else {
        needPay = needPay.mul(BigNumber.from(1006));
      }
      if (allSelectCoinNum.mul(BigNumber.from(1000)).gte(needPay)) {
        // setShowLoading(true)
        if (
          selectCoinList.length === 1 &&
          selectCoinList[0].selectInfo.address === '0x'
        ) {
          transactionData();
        } else {
          handleAporove();
        }
      } else {
        setShowLoading(false);
        createNotification('Insufficient expenses', 'error');
      }
    }
  }
  const clearCart = () => {
    if (showLoading) return;
    if (!totalSelectedCoinsUSD || totalSelectedCoinsUSD === 0) return;
    setShowLoading(true);
    setCrossBuyValue(0);
    try {
      getRealTimePrice(selectCoinList, signer, chainId).then((res) => {
        getSelectCoinNum(res);
      });
    } catch {
      setShowLoading(false);
      createNotification('getAmountsOut error', 'error');
    }
  };
  return (
    <div>
      <div className="allPay">
        {/* <div className='allPayTitle'>You pay</div> */}
        {/* <div className='allPayNum'>${numberFormat(showAllmoney)}</div> */}
        <p className="pay-desc">
          You need to pay at least{' '}
          <span className="pay-amount">${numberFormat(showAllmoney)}</span>,
          &nbsp;selected:{' '}
          <span className="pay-amount">${totalSelectedCoinsUSD}</span>
        </p>
      </div>
      <div
        className={`payButton ${buyDisabled ? 'disabled' : ''}`}
        role="button"
        tabIndex={-1}
        onKeyDown={() => {}}
        onClick={() => clearCart()}
      >
        {showLoading ? <CircularProgress className="loadings" /> : ''}
        {showLoading ? 'in transaction' : 'Buy Now'}
      </div>
      <div className="tipes">
        <div className="tipesDesc">
          Tips: Fees are exceeded in order to complete the deal. If the
          transaction fails, it will be returned.
        </div>
        <div className="feeGroup">
          <div className="feeNum">
            Message fee:
            <div className="num">~${fee}</div>
          </div>
          {/* <div className='feeNum'>
          Target chain fee:
          <div className='num'>~$0</div>
        </div> */}
        </div>
      </div>
    </div>
  );
}
export default BuyInfoComponents;
