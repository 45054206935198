import axios from 'axios';
import createNotification from './toast';

let errorDoubluce=null

function errorFunc(message){
  if(errorDoubluce) clearTimeout(errorDoubluce)
  errorDoubluce = setTimeout(()=>{
    createNotification(message,'error');
  },500)
}
const request = axios.create({
  baseURL: process.env.REACT_APP__BASE_URL,
  // baseURL:'./',
  timeout: 50000, // request timeout
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

/* eslint-disable no-param-reassign */
request.interceptors.request.use(
  (config) => {
    const auth = sessionStorage.getItem('auth');
    if (auth) {
      const { accessToken } = JSON.parse(auth);
      config.headers.Authorization = `${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

request.interceptors.response.use(
  (response) => {
    if (response.data.status === 401) {
      // logout user
      // TODO
      return Promise.reject(response);
    }
    if (response.status !== 200) {
      return Promise.reject(response);
    }
    return Promise.resolve(response.data);
  },
  (error) => {
    if (axios.isCancel(error)) {
      throw new axios.Cancel('cancel request');
    }
    errorFunc(error.message) 
    return Promise.reject(error);
  }
);

function requestPost(url,params,callback){
  return request.post(url, params)
  .then(response => {
    // @ts-ignore
    if (response && response.code === 0) {
      if(response.data){
        callback(response.data)
      }else{
        callback(true)
      }
      
    } else{
      errorFunc(response.msg);
    }
  })
}

export const post = (url,params)=> request.post(url, params)
        .then(response=>{
          if(response && response.code === 0){
            return response.data
          }
          throw response
        }).catch(error=> errorFunc(error && error.msg))

export const get = (url,params)=> request.get(url, {params})
        .then(response=>{
          if(response && response.code === 0){
            return response.data
          }
          throw response
        }).catch(error=> errorFunc(error && error.msg))

function requestGet(url,param,callback){
  return request.get(url, { params: param })
  .then(response => {
    // @ts-ignores
    if (response && response.code === 0) {
      callback(response.data)
    } else{
      errorFunc(response.msg);
    }
  })
}
function otherSystemApi(url,param,callback){
  return request.get(url, { params: param })
  .then(response => {
    // @ts-ignores
    callback(response)
  })
}
export{
  requestPost,
  requestGet,
  otherSystemApi,
};
