import React from 'react';
import PropTypes from 'prop-types';
import closedialog from '../assets/img/closedialog.png'
import triangle from '../assets/img/triangle.png'
import ShoppingListComponents from './shoppingList';
import './cartDialog.scss'

function CartDialogComponents(props){
  const {changeShowCart} = props
  return (
    <div className='cartDialogContent'>
      
      <section className='buyInfo'>
        <img className='triangle' src={triangle} alt="" />
        <h3 className='titleGroup'>
          <span>My Cart</span>
          <div 
            role="button"
            tabIndex={-1}
            onKeyDown={()=>{}} 
            onClick={()=>changeShowCart()}
          >
            <img className='closeImg' src={closedialog} alt="" />
          </div>
          
        </h3>
        <div className="listInfo">
          <ShoppingListComponents />
        </div>
       
      </section>
    </div>
  )
}

CartDialogComponents.propTypes = {
  changeShowCart:PropTypes.func.isRequired,
}
export default CartDialogComponents
