import React, { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import { utils } from 'ethers';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import rzone from '../../../assets/img/rzone.png';
import coinIcon from '../../../assets/img/coinIcon.png';
import shares from '../../../assets/img/shares.png';
import projectShopping from '../../../assets/img/projectShopping.png';
import buynow from '../../../assets/img/buynow.png';
import { formatAddress, numberFormat } from '../../../utils/tools';
import { changeShoppingList } from '../../../actions/cart';
import createNotification from '../../../utils/toast';
import { addCart, removeShoppingList } from '../../../services/cart.services';
import removeCart from '../../../assets/img/removeCart.png';
import './collectionMainInfo.scss';
import ConnectDialog from '../../../components/connectDialog';
import { getAssetListingInfo } from '../../../services/listing';

function CollectionMainInfoComponents(props) {
  const { info, slug } = props;

  const [listingInfo, setListingInfo] = useState(false);

  useEffect(async () => {
    const res = await getAssetListingInfo({
      contract: info.assetContract.address,
      tokenId: info.tokenId,
    });
    setListingInfo(res);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shoppinngList = Object.assign(
    [],
    useSelector((state) => state.cart.shoppinngList)
  );
  const ethPrice = useSelector((state) => state.ethPrice.price);
  const address = useSelector((state) => state.auth.address);
  const isConnected = useSelector((state) => state.auth.isConnected);
  const [connectDialogVisible, setConnectDialogVisible] = React.useState(false);
  let shoppingInCartIndex = 0;
  const isShowAddCart = () => {
    let isShow = true;
    for (let i = 0; i < shoppinngList.length; i += 1) {
      if (shoppinngList[i].id === info.id) {
        isShow = false;
        shoppingInCartIndex = i;
      }
    }
    return isShow;
  };
  let showAddCart = isShowAddCart();
  const clickBuyNow = (e) => {
    e.stopPropagation();
    if (!info.currentPrice) {
      createNotification("The NFTs isn't open for sale now.", 'error');
      return;
    }
    if (info.owner.address === address) {
      createNotification("'You can't buy your own NFTs.!", 'error');
      return;
    }
    if (showAddCart) {
      if (!isConnected) {
        setConnectDialogVisible(true);
        return;
      }
      addCart({ id: info.id }, () => {
        shoppinngList.push(info);
        dispatch(changeShoppingList({ shoppinngList }));
        setTimeout(() => {
          navigate(`/pay/main/`);
        }, 300);
      });
    } else {
      navigate(`/pay/main/`);
    }
  };
  const shoppingListChange = (e) => {
    e.stopPropagation();
    if (!info.currentPrice) {
      createNotification("The NFTs isn't open for sale now.", 'error');
      return;
    }
    if (showAddCart) {
      if (!isConnected) {
        setConnectDialogVisible(true);
        return;
      }

      addCart({ id: info.id }, (response) => {
        showAddCart = false;
        if (response) createNotification('success!');
        shoppinngList.push(info);
        dispatch(changeShoppingList({ shoppinngList }));
      });
    } else {
      removeShoppingList({ id: info.id }, (response) => {
        showAddCart = true;
        if (response) createNotification('success!');
        shoppinngList.splice(shoppingInCartIndex, 1);
        dispatch(changeShoppingList({ shoppinngList }));
      });
    }
  };
  const goCollection = (val) => {
    // navigate()
    navigate(`/project/main/${val.slug}`);
  };
  return (
    <section className="collectionMainInfo">
      <div className="imgGroup">
        {info.animationUrl ? (
          <video
            id="playChatVideo"
            src={info.animationUrl}
            className="collectionCover"
            controls
          >
            <track kind="captions" />
          </video>
        ) : (
          <img className="collectionCover" src={info.imageUrl} alt="" />
        )}
      </div>
      <div className="collectionInfo">
        <div
          className="title"
          onKeyDown={() => {}}
          tabIndex={-1}
          role="button"
          onClick={() => {
            goCollection(info.collection);
          }}
        >
          <span>{info.collection.name}</span>
          {info.collection.safelistRequestStatus === 'verified' ? (
            <img className="rzoneImg" src={rzone} alt="" />
          ) : (
            ''
          )}
        </div>
        <div className="collecDesc">{info.name}</div>
        {listingInfo.price ? (
          <p className="priceInfo">
            <span className="priceName">Price</span>
            <img className="priceLogo" src={coinIcon} alt="" />
            <span className="priceNum">
              {listingInfo.price}
            </span>
            <span className="PriceUsaNum">
              (${numberFormat(ethPrice * utils.formatEther(listingInfo.currentPrice))})
            </span>
          </p>
        ) : (
          ''
        )}
        <div className="collectionDescInfo">
          <p className="descInfoCom">
            <span>Contract Address</span>
            <Link
              target="_blank"
              href={`https://etherscan.io/address/${info.assetContract.address}`}
              className="linkInfo mr44"
            >
              <span>{formatAddress(info.assetContract.address)}</span>
              <img className="linkShare" src={shares} alt="" />
            </Link>
          </p>
          <p className="descInfoCom">
            <span>Listed on</span>
            <Link
              target="_blank"
              href={`https://opensea.io/collection/${slug}`}
              className="linkInfo"
            >
              <span>Opensea</span>
              <img className="linkShare" src={shares} alt="" />
            </Link>
          </p>
          <p className="descInfoCom">
            <span>Token ID</span>
            <span className="token-id" title={info.tokenId}>
              {info.tokenId}
            </span>
          </p>
          <p className="descInfoCom">
            <span>Blockchain</span>
            <span>Ethereum</span>
          </p>
          <p className="descInfoCom">
            <span>Token Standard</span>
            <span>{info.assetContract.schemaName}</span>
          </p>
          <p className="descInfoCom">
            <span>Creator Fees</span>
            <span>{info.assetContract.openseaSellerFeeBasisPoints / 100}%</span>
          </p>
        </div>
        {listingInfo && (
          <div className="buttonGroup">
            <div
              className="addCart buttoncom"
              role="button"
              tabIndex={-1}
              onKeyDown={() => {}}
              onClick={(e) => {
                shoppingListChange(e);
              }}
            >
              {showAddCart ? (
                <img className="shoppingImg" src={projectShopping} alt="" />
              ) : (
                <img className="shoppingImg" src={removeCart} alt="" />
              )}
              <span>{showAddCart ? 'Add to cart' : 'Remove'}</span>
            </div>
            <div
              className="buyNow buttoncom"
              role="button"
              tabIndex={-3}
              onKeyDown={() => {}}
              onClick={(e) => {
                clickBuyNow(e);
              }}
            >
              <img className="buynowImg" src={buynow} alt="" />
              <span>Buy now</span>
            </div>
          </div>
        )}
      </div>
      <ConnectDialog
        isVisible={connectDialogVisible}
        handleClose={() => setConnectDialogVisible(false)}
      />
    </section>
  );
}
CollectionMainInfoComponents.propTypes = {
  slug: PropTypes.string,
  info: PropTypes.shape({
    imageUrl: PropTypes.string,
    animationUrl: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    collection: PropTypes.shape({
      safelistRequestStatus: PropTypes.string,
      name: PropTypes.string,
    }),
    tokenId: PropTypes.string,
    currentPrice: PropTypes.string,
    assetContract: PropTypes.shape({
      address: PropTypes.string,
      schemaName: PropTypes.string,
      openseaSellerFeeBasisPoints: PropTypes.number,
    }),
    owner: PropTypes.shape({
      address: PropTypes.string,
    }),
  }),
};
export default CollectionMainInfoComponents;
