import { createSlice } from '@reduxjs/toolkit'

const initialCartState = {
  selectCoinList:[],
  delIndex:10000,
  shoppinngList:[],
  isClear:false,
  payAllMoney:0,
  showCartDialog:false,
}
export const cartSlice = createSlice({
  name: 'cart',
  initialState: initialCartState,
  reducers: {
    changeSelectColistList:(state,action)=>{ 
      state.selectCoinList = action.payload.selectList;
      state.delIndex =  action.payload.delIndex;
    },
    changeShoppingList:(state,action)=>{
      state.shoppinngList = action.payload.shoppinngList
    },
    clearAllList:(state,action)=>{
      state.isClear = action.payload.isClear
    },
    changePayAllMoney:(state,action)=>{
      state.payAllMoney = action.payload.payAllMoney
    },
    setTotalSelectedCoinsUSD:(state,action)=>{
      state.totalSelectedCoinsUSD = action.payload.totalSelectedCoinsUSD
    },
    changeShowCartDialog:(state,action)=>{
      state.showCartDialog = action.payload.showCartDialog
    },
  },
})

export const { changeSelectColistList,changeShoppingList,clearAllList,changePayAllMoney,changeShowCartDialog, setTotalSelectedCoinsUSD } = cartSlice.actions

// export default cartSlice
