import arbitrum from "./arbitrum.json"
import avalanche from "./avalanche.json"
import bsc from "./bsc.json"
import ethereum from "./ethereum.json"
import fantom from "./fantom.json"
import fantomTestnet from "./fantom_testnet.json"
import fuji from "./fuji.json"
import optimism from "./optimism.json"
import polygon from "./polygon.json"
import rinkeby from "./rinkeby.json"

const getTokenList = {
  "tokens0xa4b1": arbitrum,
  "tokens0xa86a": avalanche,
  "tokens0x38": bsc,
  "tokens0x1": ethereum,
  "tokens0xfa": fantom,
  "tokens0xfa2": fantomTestnet,
  "tokens0xa869": fuji,
  "tokens0xa": optimism,
  "tokens0x89": polygon,
  "tokens0x4": rinkeby,
}

export default getTokenList

