import {requestGet} from '../utils/request'

function getHomeuserAssets(params,callback){
  requestGet('/v1/user/asset/list',params,(response)=>{
    callback(response)
  })
}
function getUserEventList(params,callback){
  requestGet('/v1/user/event/list',params,(response)=>{
    callback(response)
  })
}

export {
  getHomeuserAssets,
  getUserEventList,
} 
