import { createSlice } from '@reduxjs/toolkit';

const initialCoinListState = {
  priceAggregate:{},
}
export const coinListPriceSlice = createSlice({
  name: 'coinListPrice',
  initialState: initialCoinListState,
  reducers: {
    changePrice:(state,action)=>{ 
      state.priceAggregate = action.payload.priceAggregate;
    },
    
  },
})

export const { changePrice } = coinListPriceSlice.actions

// export default coinListPriceSlice
