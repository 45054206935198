import { createSlice } from '@reduxjs/toolkit'

const initialEthPriceState = {
  price:0,
}
export const ethPriceSlice = createSlice({
  name: 'ethPrice',
  initialState: initialEthPriceState,
  reducers: {
    changePrice:(state,action)=>{ 
      state.price = action.payload.price;
    },
    
  },
})
export const { changePrice } = ethPriceSlice.actions

// export default ethPriceSlice
