const { ethers } = require("ethers");
const { BigNumber } = require("ethers");

const ABI = [
  "function fulfillAvailableAdvancedOrders(tuple(tuple(address offerer,address zone,tuple(uint8 itemType,address token,uint256 identifierOrCriteria,uint256 startAmount,uint256 endAmount)[] offer, tuple(uint8 itemType, address token, uint256 identifierOrCriteria, uint256 startAmount,uint256 endAmount,address recipient)[] consideration,uint8 orderType,uint256 startTime,uint256 endTime,bytes32 zoneHash,uint256 salt,bytes32 conduitKey,uint256 totalOriginalConsiderationItems) parameters,uint120 numerator,uint120 denominator, bytes signature, bytes extraData)[] calldata advancedOrders,tuple(uint256 orderIndex,uint8 side,uint256 index,uint256 identifier,bytes32[] criteriaProof)[] calldata criteriaResolvers,tuple(uint256 orderIndex,uint256 itemIndex)[][] calldata offerFulfillments,tuple(uint256 orderIndex,uint256 itemIndex)[][] calldata considerationFulfillments,bytes32 fulfillerConduitKey,address recipient,uint256 maximumFulfilled) external payable returns (bool[] memory availableOrders, tuple(tuple(uint8 itemType,address token, uint256 identifier, uint256 amount, address recipient) item, address offerer, bytes32 conduitKey)[] memory executions)",
];

const endCodeFulfillAvailableAdvancedOrdersCalldata = (advancedOrders,consideration,recipient,orderValue) => {
  const offerFulfillments = [[[0, 0]]];
  const considerationFulfillments = [];
  if(consideration && consideration.length > 0){
    for(let i = 0;i<consideration.length;i+=1){
      considerationFulfillments.push([[0,i]])
    }
  }
  const fulfillerConduitKey = ethers.constants.HashZero;
  const maximumFulfilled = BigNumber.from(String(advancedOrders.length));
  const iface = new ethers.utils.Interface(ABI);
  const data = iface.encodeFunctionData("fulfillAvailableAdvancedOrders", [
    advancedOrders,
    [],
    offerFulfillments,
    considerationFulfillments,
    fulfillerConduitKey,
    recipient,
    maximumFulfilled,
  ]);
  const order = {
    marketId: 2,
    targetToken: ethers.constants.AddressZero,
    // value: ethers.utils.parseEther("0.00001"),
    value: orderValue,
    orderData: data,
  };
  return order;

};

module.exports.endCodeFulfillAvailableAdvancedOrdersCalldata =
  endCodeFulfillAvailableAdvancedOrdersCalldata;
