import React, { useEffect, useState } from 'react';
import {useDispatch,useSelector } from 'react-redux';
import { useNavigate} from 'react-router-dom';
import Select from "react-select";
import {getCoinPrice} from "../../services/index.services"
import SelectPayListComponents from "./components/selectPayList"
import MyCoinListComponents from "./components/myCoinList"
import DivisionLine from '../../components/divisionLine'
import {changePrice} from "../../actions/coinListPrice"
import {getTokenInfo} from "../../utils/getTokensInfo"
// import LoadingComponents from "../../components/loading"
import {getChainInfo,getSimpleChainInfo} from "../../config/chainList"
import {connect} from "../../assets/js/common"
import {changeSelectColistList,changeShowCartDialog} from "../../actions/cart"
import getTokenList from "../../config/tokenList"
import ShoppingListComponents from "../../components/shoppingList"
import BuyInfoComponents from "./components/buyInfo"
import ComfooterComponents from '../../components/comfooter'
import './main.scss'

let allTokenList = []

function PayPage(){
  const navigate = useNavigate();

  const shoppingList = useSelector((state) => state.cart.shoppinngList) || [];
  useEffect(()=>{
    if (shoppingList.length === 0 ) {
      navigate('/')
    }
  },shoppingList)
 
  const [tokenList,setTokenList]=useState([]);
  const signer = useSelector(state => state.wallet.signer)
  const provider = useSelector(state => state.wallet.provider)
  const address = useSelector(state => state.auth.address)
  const chainId  = useSelector(state => state.wallet.chainId)
  const dispatch = useDispatch();
  const chainListOptions = getChainInfo();
  const chainSimpleInfo = getSimpleChainInfo();
  const [selectSymbol, setSelectSymbol] = useState();
  const [chainlogoName,setChainlogoName] = useState();
  const setPrice = ()=>{
    const val = getTokenList[`tokens${chainId}`]
    let ids = ""
    for(let i=0;i<val.length;i+=1){
      if(i === val.length-1){
        ids = `${ids}${val[i].coinId}`
      }else{
        ids = `${ids}${val[i].coinId},`
      }
    }
    getCoinPrice({
      ids,
      vs_currencies:'usd',
    },(response)=>{
      dispatch(changePrice({priceAggregate:response}))
    })
  }
  const seachTokenList = (e)=>{
    const newList = [];
    if(e.target.value){
      for(let i = 0;i<allTokenList.length;i+=1){
        if(allTokenList[i].name.toUpperCase().indexOf((e.target.value).toUpperCase()) > -1){
          newList.push(allTokenList[i]);
        }
      }
      setTokenList(newList)
    }else{
      setTokenList(allTokenList)
    }
    
  }
  const changeCoinList = (coinListTokens)=>{
    getTokenInfo(coinListTokens,signer,address,provider).then((response)=>{
      allTokenList = response
      setTokenList(response);
    })
  }
  useEffect(()=>{
    if(signer && chainId){
      changeCoinList(getTokenList[`tokens${chainId}`])
    }
  },[signer,chainId])
  useEffect(()=>{
    if(chainId){
      setPrice();
      const chainName = chainSimpleInfo[chainId].name
      const chainlogo = chainSimpleInfo[chainId].logoName
      setSelectSymbol(chainName)
      setChainlogoName(chainlogo);
      dispatch(changeSelectColistList({selectList:[]}));
    }
  },[chainId])
  useEffect(()=>{
    if(!sessionStorage.getItem("auth")){
      navigate('/');
    }
    dispatch(changeShowCartDialog({'showCartDialog':false}))
  },[])
  const handleChange = (selectedOption) => {
    connect(dispatch,selectedOption);
  };
  
  return (
    <div className='payMainPage'>
      <DivisionLine />
      <section className='contents'>
        <div className='payContent'>
          <div className='myCoinList'>
            <div className='searchGroup'>
              <h3>Select payment tokens</h3>
              <p className='sdesc'>Add any items to your cart and proceed to checkout</p>
              <input 
                className='searchInput' 
                type="text" 
                placeholder='Search tokens by name, toekn address' 
                onChange={(e)=>{seachTokenList(e)}}
              />
            </div>
            <div className='resulyList'>
              {
                tokenList && tokenList.length >0?

                tokenList.map((item,index)=>
                <div className='selectList' key = {item.coinId}>
                  
                  <MyCoinListComponents listInfo = {item} listIndex = {index}/>
                </div>
                ):''
              }
            </div>
          </div>

          <div className='selectPayList'>
            <div className='shoppingList'>
              <div className='selectedName'>Selected</div>
              <ShoppingListComponents />
            </div>
            <div className='payWith'>
              <h4 className='payName'>Pay With</h4>
              <div className='select'>
                <img className='iconInfo' src={chainlogoName} alt="" />
                <Select className='selectListOptions' 
                  placeholder = {selectSymbol} 
                  value={selectSymbol} 
                  onChange={handleChange} 
                  options={chainListOptions}
                />
              </div>
            </div>
            <SelectPayListComponents/>
            <BuyInfoComponents />
          </div>
        </div>
      </section>
      
      <ComfooterComponents />
    </div>
  )
}
export default PayPage
