import React from 'react';
import './collectionsTable.scss'
import PropTypes, { shape } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {numberFormat} from "../utils/tools"
import rzone from '../assets/img/rzone.png'
import coinIcon from '../assets/img/coinIcon.png'
import userlogo from '../assets/img/userlogo.png'

function CollectionComponents(props){
  const {name,list,noshowIndex = false} = props
  const navigate = useNavigate()
  const goNextPage = (item)=>{
    navigate(`/project/main/${item.slug}`)
  }
  function listcom(item,index){
    return <tr key = {index}>
      <td className={noshowIndex?'noindex':'tableone'}>{noshowIndex?'':index+1}</td>
      <td 
        className='tabletwo'
      >
        <div 
          className='addLink'
          role="button"
          tabIndex = {-1}
          onKeyDown={()=>{}}
          onClick={()=>{goNextPage(item)}}
        >
          <img className='iconlogo' src={item.imageUrl?item.imageUrl:userlogo} alt="iconlogo" />
          <p className='name'>{item.name}</p>
          {item.safelistRequestStatus === 'verified'?<img className='rzone' src={rzone} alt="rzone" />:''}
        </div>
      </td>
      <td className='tableother'>
        <img className='coinIcon' src={coinIcon} alt="icon" />
        {
          item.stats && item.stats.totalVolume?
          <span>{numberFormat(parseInt(item.stats.totalVolume, 10))}</span>
          :
          ''
        }
        
      </td>
      <td className='tableother'>
        <img className='coinIcon' src={coinIcon} alt="icon" />
        <span>{numberFormat(parseInt(item.stats.oneDayVolume, 10))}</span>
        <span className={`${item.stats.oneDayChange-0<0?'addnum less':'addnum'}`}>{parseFloat(item.stats.oneDayChange*100).toFixed(2)}%</span>
      </td>
      <td className='tableother'>
        <img className='coinIcon' src={coinIcon} alt="icon" />
        <span>{item.stats.floorPrice?numberFormat(parseInt(item.stats.floorPrice, 10)):'-'}</span>
      </td>
      <td className='tableother'>{item.stats.numOwners?numberFormat(parseInt(item.stats.numOwners, 10)):'-'}</td>
      <td className='tableother'>{item.stats.totalSales?numberFormat(parseInt(item.stats.totalSales, 10)):'-'}</td>
    </tr>
  }
  return (
    <section className='collections'>
      <h2>{name}</h2>
      <table className='collections-table'>
        <thead>
          <tr>
            <th className={noshowIndex?'noindex':'tableone'}> </th>
            <th className='tabletwo'>Collection</th>
            <th className='tableother'>Total Vol</th>
            <th className='tableother'>24h Volume</th>
            <th className='tableother'>Floor Price</th>
            <th className='tableother'>Owners</th>
            <th className='tableother'>Items</th>
          </tr>
        </thead>
        <tbody>
          {
            list.map((item,index)=> listcom(item,index))
          }
          
        </tbody>
      </table>
    </section>
  )
}
CollectionComponents.propTypes = {
  name:PropTypes.string.isRequired,
  list:PropTypes.arrayOf(shape({
    imageUrl:PropTypes.string,
    name:PropTypes.string,
    safelistRequestStatus:PropTypes.string,
    stats:PropTypes.shape({
      totalVolume:PropTypes.number,
      oneDayVolume:PropTypes.number,
      oneDayChange:PropTypes.number,
      floorPrice:PropTypes.number,
      numOwners:PropTypes.number,
      totalSales:PropTypes.number,
    }),
  })),
  noshowIndex:PropTypes.bool,
}
export default CollectionComponents
