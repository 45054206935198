import React, { useEffect, useState } from 'react';
import {useSelector,useDispatch } from 'react-redux';
import { changeSelectColistList,clearAllList ,setTotalSelectedCoinsUSD} from '../../../actions/cart';
// import PayAllInfoComponents from './buyInfo'
import {numberFormat} from "../../../utils/tools"
import NoDataComponents from "../../../components/noData"
import closedialog from '../../../assets/img/closedialog.png'
import './selectPayList.scss'


function SelectPayListComponents(){
  const dispatch = useDispatch();
  const selectCoinList = Object.assign([],useSelector((state) => state.cart.selectCoinList))
  const coinPrice = useSelector((state) => state.coinListPrice.priceAggregate)
  const isClear = useSelector((state) => state.cart.isClear)
  const [selectAllfee,setSelectAllfee] = useState('0')
  
  const delateCoin =(index)=>{
    const delSelectIndex = selectCoinList[index].selectIndex
    selectCoinList.splice(index,1)
    dispatch(changeSelectColistList({selectList:selectCoinList,delIndex:delSelectIndex}));
  }
  const clearAllSelect = ()=>{
    dispatch(clearAllList({isClear:!isClear}));
    dispatch(changeSelectColistList({selectList:[]}));
  }
  function getSelectFee(){
    let allFee = 0;
    if(selectCoinList && selectCoinList.length > 0){  
      for(let i=0;i<selectCoinList.length;i+=1){
        const price = coinPrice[selectCoinList[i].selectInfo.coinId]?coinPrice[selectCoinList[i].selectInfo.coinId].usd:0
        allFee +=Number(price*selectCoinList[i].selectVal)
        // if(selectCoinList[i].selectInfo.coinId){
        //   allFee +=Number(coinPrice[selectCoinList[i].selectInfo.coinId].usd*selectCoinList[i].selectVal)
        // }else{
        //   allFee +=Number(coinPrice[selectCoinList[i].selectInfo.replaceCoinId].usd*selectCoinList[i].selectVal)
        // }
        
      }
      setSelectAllfee(Number(allFee).toFixed(2))
      dispatch(setTotalSelectedCoinsUSD({totalSelectedCoinsUSD:Number(allFee).toFixed(2)}))
    }else{
      setSelectAllfee('0');
      dispatch(setTotalSelectedCoinsUSD({totalSelectedCoinsUSD:'0'}))
    }
  }
  useEffect(()=>{
    getSelectFee()
  },[selectCoinList,coinPrice]);
  return (
    <div>
      <div className='selectTokens'>
        <div className='listInfo headerInfo'>
          <div className='leftContent'>Tokens</div>
          <div className='centerContent'>Selected</div>
          <div className='rightContent'>Clear</div>
        </div>
        {
          selectCoinList && selectCoinList.length > 0?
          <div>
            <div className='listInfo totalInfo'>
              <div className='leftContent'>
                <div className='numberContent'>
                  <div className='number'>{selectCoinList?selectCoinList.length:0}</div>
                  <div className='numberName'>Total</div>
                </div>
                
              </div>
              <div className='centerContent'>
                <div className='number'>${numberFormat(selectAllfee)}</div>
                <div className='numberName'>Total</div>
              </div>
              <div 
                className='rightContent'
                role="button"
                tabIndex = {-1}
                onKeyDown = {()=>{}}
                onClick = {()=>{
                  clearAllSelect()
                }}
              >
                <img className='selectClose' src={closedialog} alt="" />
              </div>
            </div>{
              selectCoinList.map((selInfo,index)=>
                <div className='listInfo' key = {selInfo.selectInfo.coinId}>
                  <div className='leftContent coinListLeft'>
                    <img className='coinLogo' src={selInfo.selectInfo.logoURI} alt="" />
                    <div className='coinName'>{selInfo.selectInfo.name}</div>
                  </div>
                  <div className='centerContent coinPrice'>
                    Balance <span className='banlance'>{selInfo.selectVal}</span> (${coinPrice[selInfo.selectInfo.coinId] ? numberFormat(coinPrice[selInfo.selectInfo.coinId].usd*selInfo.selectVal):0})
                  </div>
                  <div className='rightContent'
                    role="button"
                    tabIndex= {-1}
                    onKeyDown = {()=>{}}
                    onClick = {()=>{delateCoin(index)}}
                  >
                    <img className='selectClose' src={closedialog} alt="" />
                  </div>
                </div>
              )
            }
          </div>
          :<NoDataComponents/>
        }
      </div>
      {/* <PayAllInfoComponents/> */}
    </div>
    
    
  )
}
export default SelectPayListComponents
