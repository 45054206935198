import { createSlice } from '@reduxjs/toolkit'

const authData = JSON.parse(sessionStorage.getItem('auth'));
const initialAuthState = authData?
{
  accessToken: authData.accessToken, 
  isConnected: true,
  address:authData.address,
}:{
  accessToken: null,
  isConnected: false,
  address:null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    userLogin:(state,action)=>{ 
      state.accessToken = action.payload.accessToken;
      state.isConnected = true;
      state.address = action.payload.address;
    },
    userLogout:(state)=>{
      state.accessToken = null;
      state.isConnected = false;
      state.address = null;
    },
    
  },
})

export const { userLogin,userLogout } = authSlice.actions

// export default authSlice
