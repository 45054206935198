import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom'
import {getAssetDetail}from "../../services/collectionDetail.services"
import DivisionLine from '../../components/divisionLine'
import CollectionMainInfoComponents from './components/collectionMainInfo'
import PropertiesComponents from './components/properties'
import ActivityTables from './components/activity'
import ComfooterComponents from '../../components/comfooter'
import './main.scss'

function CollectionDetailPage(){
  const{contract,tokenId,slug} = useParams();
  const [assetInfo,setAssetInfo] = useState({id:'',traits:[]});
  function getDetail(){
    getAssetDetail({
      contract,
      tokenId,
    },(response)=>{
      setAssetInfo(response)
    });
  }
  useEffect(()=>{
    getDetail()
  },[contract,tokenId,slug])
  return (
    <div className='collectionDetailPageMain'>
      <DivisionLine />
      <div className='collectionContent '>
        {
          assetInfo && assetInfo.id?
          <div>
            <CollectionMainInfoComponents slug={slug} info = {assetInfo} />
            <PropertiesComponents traits = {assetInfo.traits} />
          </div>
          :''
        }
        <ActivityTables slug = {slug} tokenId = {tokenId} contract={contract}/>
      </div>
      <ComfooterComponents />
    </div>
  )
}
export default CollectionDetailPage
