import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from '../utils/tools';
// import getHomeSearch from "../services/search.services"
import { searchCollection, searchAsset } from '../services/search.services';
import searchIcon from '../assets/img/searchIcon.png';
import bigrz from '../assets/img/bigrz.png';
import openinfo from '../assets/img/openinfo.png';
import './searchModule.scss';

let divElement = null;
// let inputElement = null
function SearchMoudleComponents() {
  const navigate = useNavigate();
  // let debounceTiger = null;
  const [showDialog, setShowDialog] = useState(false);
  const [showCollection, setShowCollection] = useState(false);
  const [showAsset, setShowAsset] = useState(false);
  const [collectionsList, setCollectionsList] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [collectionsListMore, setCollectionsListMore] = useState(false);
  const [itemsListMore, setItemsListMore] = useState(false);
  const onSearch = (event) => {
    const text = event.target.value;
    if (!text && text !== 0) return;

    searchCollection({ text }).then((response) => {
      const { collections } = response;
      if (collections && collections.length > 0) {
        setShowCollection(true);
        setCollectionsList(response.collections);
      } else {
        setShowCollection(false);
      }
    });

    searchAsset({ text }).then((response) => {
      const { assets } = response;
      if (assets && assets.length > 0) {
        setShowAsset(true);
        setItemsList(response.assets);
      } else {
        setShowAsset(false);
      }
    });
  };

  useEffect(() => {
    setShowDialog(showCollection || showAsset);
  }, [showCollection, showAsset]);

  const gonextpage = (item, type) => {
    setShowDialog(false);
    if (type === '1') {
      navigate(`/project/main/${item.slug}`);
    } else {
      navigate(
        `/collectionDetail/main/${item.assetContract.address}/${item.tokenId}/${item.collection.slug}`
      );
    }
  };
  const outDivClickHandler = (e) => {
    const { target } = e;
    e.stopPropagation();
    if (divElement && !divElement.contains(target)) {
      setShowDialog(false);
    }
  };
  document.addEventListener('click', outDivClickHandler);

  function collectionlist(item, index) {
    return (
      <div
        className="listInfo"
        role="button"
        tabIndex={-3}
        key={index}
        onKeyDown={() => {}}
        onClick={() => {
          gonextpage(item, '1');
        }}
      >
        <img className="icLogo" src={item.imageUrl} alt="" />
        <p className="icName">{item.name}</p>
        {item.safelistRequestStatus === 'verified' ? (
          <img className="rzimg" src={bigrz} alt="" />
        ) : (
          ''
        )}
      </div>
    );
  }
  function itemlist(item, index) {
    return (
      <div
        className="listInfo itemsInfo"
        key={index}
        role="button"
        tabIndex={-4}
        onKeyDown={() => {}}
        onClick={() => {
          gonextpage(item, '2');
        }}
      >
        <img className="icLogo" src={item.imageUrl} alt="" />
        <div className="icNamegroup">
          <h3 className="tname">{item.name}</h3>
          <p className="cname">
            <span className="labels">{item.collection.name}</span>
            {item.collection.safelistRequestStatus === 'verified' ? (
              <img className="rzimg" src={bigrz} alt="" />
            ) : (
              ''
            )}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="inputgroup">
      <img className="iconName" src={searchIcon} alt="" />
      <input
        className="headerInput"
        placeholder="Search items, collections"
        onChange={debounce(onSearch, 500)}
        onFocus={() => {
          if (collectionsList.length > 0 || itemsList.length > 0) {
            setTimeout(() => {
              setShowDialog(true);
            }, 200);
          }
        }}
        type="text"
      />
      <div
        ref={(node) => {
          divElement = node;
          return divElement;
        }}
        className={`searchDialog ${showDialog ? 'showdialog' : ''}`}
      >
        {collectionsList && collectionsList.length > 0 ? (
          <div className="resultMoudle">
            <h3 className="moudleTitle">Collections</h3>
            <div
              className={`resultList ${collectionsListMore ? 'morelist' : ''}`}
            >
              {collectionsList.map((item, index) =>
                collectionlist(item, index)
              )}
            </div>
            {collectionsList && collectionsList.length > 12 ? (
              <div
                className={`addmore ${
                  collectionsListMore ? 'hideaddmore' : ''
                }`}
                role="button"
                tabIndex={-2}
                onKeyDown={() => {}}
                onClick={() => {
                  setCollectionsListMore(true);
                  // setShowDialog(true)
                }}
              >
                <span>More</span>
                <img className="moreicon" src={openinfo} alt="" />
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
        {itemsList && itemsList.length > 0 ? (
          <div className="resultMoudle">
            <h3 className="moudleTitle">Items</h3>
            <div
              className={`resultList itemslist ${
                itemsListMore ? 'morelist' : ''
              }`}
            >
              {itemsList.map((item, index) => itemlist(item, index))}
            </div>
            {itemsList && itemsList.length > 12 ? (
              <div
                className={`addmore ${itemsListMore ? 'hideaddmore' : ''}`}
                role="button"
                tabIndex={-5}
                onKeyDown={() => {}}
                onClick={() => {
                  setItemsListMore(true);
                }}
              >
                <span>More</span>
                <img className="moreicon" src={openinfo} alt="" />
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
export default SearchMoudleComponents;
