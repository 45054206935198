import React, { useEffect } from 'react';
import { createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { changeChainId } from './actions/wallet';
import Headercom from './components/comheader';
import IndexPage from './pages/index/main';
import ProjectMain from './pages/project/main'
import CollectionDetailPage from './pages/collectionDetail/main'
import CollectionListPage from './pages/collectionList/main'
import UserMainPage from './pages/user/main'
// import ComfooterComponents from './components/comfooter'
import PayPage from './pages/pay/main'
import './App.scss';
import './assets/css/restMuiCss.scss'
import getEthPrice from "./services/ethPrice.services"
import {changePrice} from './actions/ethPrice'
import createNotification from './utils/toast'
import {onConnect} from "./assets/js/common"
import {getProvider} from "./utils/tools"


async function checkIfWalletIsConnected(dispatch) {
  if (window.ethereum) {
    const accounts = await window.ethereum.request({
      method: 'eth_accounts',
    });
    if (accounts.length > 0) {
      // @ts-ignore
      const provider = getProvider()
      const signer = provider.getSigner();
      const account = accounts[0];
      onConnect(account, provider, signer,dispatch);
    }
    const getChainId = await window.ethereum.request({ method: 'eth_chainId' })
    dispatch(changeChainId({'chainId':getChainId}));
    
  }else{
    createNotification('error','error');
  }
}
function App() {
  const theme = createTheme({
    typography: {
      htmlFontSize: 100,
      button: {
        fontSize: '0.2rem',
      },
      h1: {
        fontSize: '1rem',
        fontWeight: '400',
      },
      h3: {
        fontSize: '0.5rem',
        fontWeight: 'bold',
      },
      h4: {
        fontSize: '0.4rem',
        fontWeight: 'bold',
      },
      h5: {
        fontSize: '0.3rem',
        fontWeight: 'bold',
      },
      h6: {
        fontSize: '0.2rem',
        fontWeight: '400',
      },
      subtitle1: {
        fontSize: '0.3rem',
      },
    },
    palette: {
      primary: {
        main: '#17B449',
        contrastText: '#fff',
      },
      grey: {
        // @ts-ignore
        main: '#999999',
        contrastText: '#fff',
      },
    },
  });
  const resizeListener = () => {
    const designSize = 1920;
    const html = document.documentElement;
    const clientW = html.clientWidth;
    const htmlRem = (clientW * 100) / designSize;
    html.style.fontSize = `${htmlRem}px`;
    theme.typography.htmlFontSize = htmlRem;
  };
  const appDispatch = useDispatch()
  const getRealTimeEthPriceNow =()=>{
    getEthPrice((priceNumber)=>{
      appDispatch(changePrice({price:priceNumber}));
    })
  }
  useEffect(() => {
    getRealTimeEthPriceNow()
    // setInterval(()=>{
    //   getRealTimeEthPriceNow()
    // },10000);
    checkIfWalletIsConnected(appDispatch);
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  },[]);
  
  

  return (
    <div className="App">
      <Router>
        <Headercom />
        <div className='mainContent'>
          <Routes>
            {/* Home */}
            <Route exact path="/" element={<IndexPage />}/>

            {/* project Home */}
            <Route exact path = "/project/main/:slug" element={<ProjectMain />} />

            {/* project Details */}
            <Route exact path = "/collectionDetail/main/:contract/:tokenId/:slug" element={<CollectionDetailPage />} />

            {/* project List */}
            <Route exact path ="/collectionList/main" element={<CollectionListPage />} />
            
            {/* user */}
            <Route exact path = "/user/main" element={<UserMainPage />} />

            <Route exact path = "/pay/main" element={<PayPage />} />
            
          </Routes>
        </div>
        {/* <ComfooterComponents/> */}
      </Router>
    </div>
  );
}

export default App;

